<template>
  <div class="align-self-center">
    <v-dialog
        v-model="dialogShow"
        :style="{ zIndex: formDialogOptions.zIndex }"
        @keydown.esc.prevent="closeDialog"
        transition="dialog-top-transition"
    >
      <v-card>
        <v-toolbar
            class="grey lighten-3"
            elevation="0"
            dense
        >

          <v-toolbar-title>Read File Excel Pasar</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              color="red"
              icon
              @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="justify-center">

          <v-row justify="center" align="center">
            <v-col cols="5">
              <v-file-input
                  v-model="currentFile"
                  show-size
                  counter
                  label="Pilih File Excel"
                  accept=".xls,.xlsx"
                  @change="selectFile"
              ></v-file-input>
            </v-col>
            <v-col cols="3" class="pl-1">
              <v-autocomplete
                  v-model="finfrastructureTypeBeanSelected"
                  :rules="rulesNotEmtpy"
                  :items="itemsFInfrastructureType"
                  item-value="id"
                  item-text="description"
                  auto-select-first
                  dense
                  chips
                  deletable-chips
                  color="blue-grey lighten-2"
                  label="Jenis Infras"
                  hint="Jenis Infras"
                  persistent-hint
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" class="pl-1">
              <v-autocomplete
                  v-model="fdivisionBeanSelected"
                  :items="itemsFDivision"
                  item-value="id"
                  item-text="description"
                  dense
                  chips
                  deletable-chips
                  color="blue-grey lighten-2"
                  label="Division"
                  hint="Kosong, maka akan disesuikan dengan kab/kota masing2"
                  persistent-hint
              ></v-autocomplete>
            </v-col>

            <v-col cols="2" class="pl-2">
              <v-btn color="success" small @click="startUpload" :disabled="!currentFile || finfrastructureTypeBeanSelected==='' || sudahUpload===true">
                Upload
                <v-icon right dark>mdi-cloud-upload</v-icon>
              </v-btn>
            </v-col>


          </v-row>

          <v-row v-if="currentFile">
            <v-col cols="12" sm="12" md="12">

              <v-data-table
                  :headers="headers"
                  :items="itemsFInfrastructureComputed"
                  item-key="name"
                  class="elevation-1"
                  height="400"
                  calculate-widths
                  hide-default-footer
                  disable-pagination
              >
                <template v-slot:[`item.number`]="{ item }" >
                  <span><v-icon small color="green" v-if="item.statusUpload===1">mdi-check-circle</v-icon></span>
                  <span><v-icon small color="red" v-if="item.statusUpload===2">mdi-close</v-icon></span>
                  {{ item.number }}
                </template>

              </v-data-table>
            </v-col>

          </v-row>

          <div v-if="progress">
            <div>
              <v-progress-linear
                  v-model="progress"
                  color="light-blue"
                  height="25"
                  reactive
              >
                <strong>{{ progress }} %</strong>
              </v-progress-linear>
            </div>
          </div>

          <v-row v-if="previewImage" justify="center">
            <v-img class="ma-2" :src="previewImage" alt="" max-width="300px" max-height="300px"></v-img>
          </v-row>
        </v-card-text>
      </v-card>

      <v-alert v-if="message" border="left" color="blue-grey" dark>
        {{ message }}
      </v-alert>

    </v-dialog>

    <v-dialog
        v-model="dialogProgres"
        hide-overlay
        persistent
        width="300"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text>
          Please Wait...
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import readXlsxFile from 'read-excel-file'
import FInfrastructure from "@/models/f-infrastructure";
import FInfrastructureService from "../../../services/apiservices/f-infrastructure-service"

export default {
  name: "ImportExcel",
  props:{
    parentId: Number,
    itemsFDivision: [],
    itemsFInfrastructureType: [],
    itemsFArea: [],
    itemsFSubArea: [],

  },
  components:{
  },
  data() {
    return {
      sudahUpload: false,

      dialogProgres: false,
      dialogShow: false,
      formDialogOptions: {
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },

      currentFile: undefined,
      previewImage: undefined,

      fdivisionBeanSelected: '',
      finfrastructureTypeBeanSelected: '',
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],

      progress: 0,
      message: "",
      description: '',

      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'Kode', value: 'kode1' },
        { text: 'Nama', value: 'description' },
        { text: 'Jenis', value: 'remark1' },
        { text: 'Fungsi', value: 'remark2' },
        { text: 'Jml Pedagang', value: 'remarkInt1' },
        { text: 'Jml PDGA', value: 'remarkInt2' },
        { text: 'Luas', value: 'luas' },
        { text: 'Kecamatan', value: 'district' },
        { text: 'Kab/Kota', value: 'city' },
        { text: 'Lat', value: 'lat' },
        { text: 'Lon', value: 'lon' },
      ],
      itemsFInfrastructure:[],
    };
  },
  computed: {
    itemsFInfrastructureComputed(){
      return this.itemsFInfrastructure
    }
  },

  methods: {

    selectFile(file) {
      this.currentFile = file;
      // this.previewImage = URL.createObjectURL(this.currentFile);
      this.progress = 0;
      this.message = "";

      let rowKe =0
      readXlsxFile(this.currentFile).then( (rows) =>{
        rows.forEach( item => {
          if (rowKe >0){
            // console.log(JSON.stringify(item[1]))
            // console.log(JSON.stringify(item[1]))

            let fFInfrastructureBean = new FInfrastructure()
            fFInfrastructureBean.number = rowKe+1

            // if (item[1].toUpperCase() ==="FISIK" || item[1].toUpperCase() ==="FSK" ) {
            //   fFInfrastructureBean.jenisPekerjaan = "FSK"
            // }else {
            //   fFInfrastructureBean.jenisPekerjaan = "NONFSK"
            // }

            fFInfrastructureBean.statusUpload =0
            fFInfrastructureBean.kode1 = item[0]!==null?item[0].trim():''
            fFInfrastructureBean.description = item[1]!==null?item[1].trim():''
            fFInfrastructureBean.remark1 = item[2]!==null?item[2].trim():''
            fFInfrastructureBean.remark2 = item[3]!==null?item[3].trim():''
            fFInfrastructureBean.remarkInt1 = item[4]!==null?item[4].trim():''
            fFInfrastructureBean.remarkInt2 = item[5]!==null?item[5].trim():''

            fFInfrastructureBean.luas = item[6]!==null?Number(item[6]):0
            fFInfrastructureBean.district = item[7]!==null?item[7].trim():''
            fFInfrastructureBean.city = item[8]!==null?item[8].trim():''
            fFInfrastructureBean.lat = item[9]!==null?Number(item[9]):0.0
            fFInfrastructureBean.lon = item[10]!==null?Number(item[10]):0.0

            // if (isNaN(Date.parse(fFInfrastructureBean.tglAdendum))){
            //   fFInfrastructureBean.tglAdendum = '2020-01-01'
            // }

            this.itemsFInfrastructure.push(fFInfrastructureBean)

          }

          rowKe++
        })
      })

    },

    startUpload() {
      if (!this.currentFile) {
        this.message = "Please select an Image!";
        return;
      }
      if (
          this.finfrastructureTypeBeanSelected ===undefined || this.finfrastructureTypeBeanSelected===''
      ) {
        this.message = "Tipe Infrastruktur Belum Dipilih!";
        return;
      }

      let items = []
      this.itemsFInfrastructure.forEach( item =>{
        let newItem = item
        newItem.id = 0
        newItem.finfrastructureTypeBean = this.finfrastructureTypeBeanSelected

        if (this.fdivisionBeanSelected !==''){
          newItem.fdivisionBean = this.fdivisionBeanSelected
        }else{
          try{
            const div = this.itemsFDivision.filter(x => x.description.trim().toUpperCase() === newItem.city.trim().toUpperCase()  )
            newItem.fdivisionBean = div[0].id
          }catch (e) {
            e.toString()
          }
        }

        try{
          const kabKota = this.itemsFArea.filter(x => x.description.trim().toUpperCase() === newItem.city.trim().toUpperCase() )
          newItem.fareaBean = kabKota[0].id
        }catch (e) {
          e.toString()
        }
        try{
          const kecamatan = this.itemsFSubArea.filter(x => x.description.trim().toUpperCase() === newItem.district.trim().toUpperCase() )
          newItem.fsubAreaBean = kecamatan[0].id
        }catch (e) {
          e.toString()
        }

        if (newItem.fdivisionBean>0){
          items.push(newItem)
        }

      })


      FInfrastructureService.createFInfrastructureMultiple(items).then(
          response =>{

            // console.log(response.data.length)

            this.itemsFInfrastructure = []

            response.data.forEach( itemResponse => {
              const newItem = Object.assign({}, itemResponse)
              try {
                this.$nextTick(() => {
                  newItem.statusUpload = 1
                  this.itemsFInfrastructure.push(newItem)
                })
              }catch (e) {
                newItem.statusUpload = 2
                this.itemsFInfrastructure.push(newItem)
                console.log(e)
              }

            })
            this.sudahUpload = true
          },
          error =>{
            console.log(error.data)
          }
      )
      

    },

    showDialog(){
      this.dialogShow = true
      this.currentFile = undefined
      this.previewImage = undefined
      this.progress =0
      this.message = ""

      this.sudahUpload = false

      this.itemsFInfrastructure = []

    },
    closeDialog(){
      if (! this.isItemModified){
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
      }
      if (this.sudahUpload) {
        this.$emit('importExcelCompleted', "OKE")
      }

    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },


  },
  mounted() {
  },
};
</script>

<style scoped>

</style>
