<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeForm"
      persistent
      fullscreen
    >
      <v-card>
        <v-form v-model="valid" ref="form">
          <v-toolbar dark color="brown" class="color-gradient-1" dense>
            <v-btn icon dark @click="closeForm">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM'">EDIT </span>
            <span v-if="formMode === 'NEW_FORM' && lang === 'en'"> NEW</span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM' && lang === 'id'"> BARU</span>

            <span
              class="ml-2 mr-2 font-weight-medium grey--text"
              v-show="isItemModified"
            >
              |
            </span>
            <v-chip
              class="ma-2"
              color="warning"
              outlined
              x-small
              v-show="isItemModified"
            >
              <v-icon left> mdi-pencil </v-icon>
              modfied
            </v-chip>

            <v-spacer></v-spacer>
            <v-toolbar-items class="mx-0">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    v-bind="attrs"
                    v-on="on"
                    rounded
                    icon
                    class="px-5"
                    x-small
                  >
                    <v-icon color="black" v-if="false">mdi-translate</v-icon>
                    <v-avatar size="18" class="elevation-1">
                      <v-img :src="getFlagImage(lang)"></v-img>
                    </v-avatar>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, index) in menu_language"
                    :key="index"
                    dense
                  >
                    <v-list-item-title>
                      <v-avatar size="22" class="elevation-1">
                        <v-img :src="getFlagImage(item.id)"></v-img>
                      </v-avatar>
                      <v-btn
                        small
                        plain
                        elevation="0"
                        @click="languageChange(item.id)"
                      >
                        {{ item.description }}
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar-items>

            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="save"
                :disabled="!valid || isItemModified === false"
                class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-title>
            <v-container class="pa-4 text-center">
              <v-row class="fill-height" align="center" justify="center">
                <template>
                  <v-col cols="12" md="4">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :elevation="hover ? 10 : 1"
                        :class="[
                          { 'on-hover': hover },
                          hover
                            ? 'card-hover-opacity'
                            : 'card-not-hover-opacity',
                        ]"
                      >
                        <v-img
                          :lazy-src="lookupImageUrlLazy(itemModified)"
                          :src="lookupImageUrl(itemModified)"
                          height="225px"
                        >
                          <v-card-title class="text-h6 white--text fill-height">
                            <v-row
                              class="fill-height flex-column"
                              justify="space-between"
                            >
                              <v-spacer />
                              <div class="align-self-center">
                                <v-btn
                                  :class="{ 'show-btns': hover }"
                                  :color="transparent"
                                  icon
                                  large
                                  dark
                                  outlined
                                  @click="showDialogUpload"
                                >
                                  <v-icon
                                    :class="{ 'show-btns': hover }"
                                    :color="transparent"
                                    large
                                  >
                                    mdi-upload
                                  </v-icon>
                                </v-btn>
                              </div>
                            </v-row>
                          </v-card-title>
                        </v-img>
                      </v-card>
                    </v-hover>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="itemModified.kode1"
                    label="Kode"
                    :rules="rulesNotEmtpy"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <v-text-field
                    v-if="lang === 'id'"
                    v-model="itemModified.description"
                    :rules="rulesNotEmtpy"
                    label="Nama Infrastructure"
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-if="lang === 'en'"
                    v-model="itemModified.descriptionEn"
                    :rules="rulesNotEmtpy"
                    label="Infrastructure Name"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-switch
                    v-if="lang === 'id'"
                    v-model="itemModified.statusActive"
                    :label="itemModified.statusActive ? 'Aktif' : 'Non-Aktif'"
                    x-small
                  ></v-switch>
                  <v-switch
                    v-if="lang === 'en'"
                    v-model="itemModified.statusActive"
                    :label="itemModified.statusActive ? 'Active' : 'Non-Active'"
                    x-small
                  ></v-switch>
                </v-col>

                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    v-model="itemModified.tahun"
                    :label="lang === 'id' ? 'Tahun' : 'Year'"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="itemModified.fdivisionBean"
                    :items="itemsFDivision"
                    :rules="rulesNotEmtpy"
                    item-value="id"
                    item-text="description"
                    auto-select-first
                    chips
                    deletable-chips
                    color="blue-grey lighten-2"
                    :label="
                      lang === 'id'
                        ? 'Pemilik Data (Kab/Kota)'
                        : 'Data Owner (District/City)'
                    "
                    :hint="
                      lang === 'id'
                        ? `Pemilik Data (Kab/Kota) : ${
                            lookupFDivision(itemModified.fdivisionBean).kode1
                          }`
                        : `Data Owner (District/City) : ${
                            lookupFDivision(itemModified.fdivisionBean).kode1
                          }`
                    "
                    persistent-hint
                    dense
                    single-line
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="itemModified.finfrastructureTypeBean"
                    :items="itemsFInfrastructureType"
                    :rules="rulesNotEmtpy"
                    item-value="id"
                    item-text="description"
                    auto-select-first
                    dense
                    chips
                    deletable-chips
                    color="blue-grey lighten-2"
                    :label="
                      lang === 'id'
                        ? 'Jenis Infrastructure'
                        : 'Infrastructure Type'
                    "
                    :hint="
                      lang === 'id'
                        ? `Jenis Infrastrure : ${
                            lookupFInfratructureType(
                              itemModified.finfrastructureTypeBean
                            ).kode1
                          }`
                        : `Infrastuscture Type : ${
                            lookupFInfratructureType(
                              itemModified.finfrastructureTypeBean
                            ).kode1
                          }`
                    "
                    persistent-hint
                    single-line
                    @change="finfrastructureTypeChange"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="itemModified.address"
                    :label="lang === 'id' ? 'Alamat' : 'Address'"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="itemModified.district"
                    :label="lang === 'id' ? 'Kecamatan' : 'Sub-District'"
                    :rules="rulesNotEmtpy"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="itemModified.city"
                    :label="lang === 'id' ? 'Kab-Kota' : 'District/City'"
                    :rules="rulesNotEmtpy"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-autocomplete
                    v-model="itemModified.fareaBean"
                    :items="itemsFArea"
                    item-value="id"
                    item-text="description"
                    auto-select-first
                    chips
                    deletable-chips
                    color="blue-grey lighten-2"
                    :label="
                      lang === 'id' ? 'Kode Kab/Kota' : 'District/City Code'
                    "
                    :hint="
                      lang === 'id'
                        ? `Kode Kab/Kota : ${
                            lookupFArea(itemModified.fareaBean).kode1
                          }`
                        : `District/City Code : ${
                            lookupFArea(itemModified.fareaBean).kode1
                          }`
                    "
                    persistent-hint
                    single-line
                    dense
                    @change="fareaChange"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="3" md="3">
                  <v-autocomplete
                    v-model="itemModified.fsubAreaBean"
                    :items="itemsFSubAreaComputed"
                    item-value="id"
                    item-text="description"
                    auto-select-first
                    chips
                    deletable-chips
                    color="blue-grey lighten-2"
                    :label="
                      lang === 'id' ? 'Kode Kecamatan' : 'Sub-District Code'
                    "
                    :hint="
                      lang === 'id'
                        ? `Kode Kecamatan : ${
                            lookupFSubArea(itemModified.fsubAreaBean).kode1
                          }`
                        : `Sub-District Code : ${
                            lookupFSubArea(itemModified.fsubAreaBean).kode1
                          }`
                    "
                    persistent-hint
                    single-line
                    dense
                    @change="fsubAreaChange"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="itemModified.state"
                    :label="lang === 'id' ? 'Provinsi' : 'Province'"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    prepend-inner-icon="mdi-google-maps"
                    v-model="itemModified.lat"
                    label="Latitude"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    prepend-inner-icon="mdi-google-maps"
                    v-model="itemModified.lon"
                    label="Longitude"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="itemModified.website"
                    label="Website"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    prepend-inner-icon="mdi-email"
                    v-model="itemModified.email"
                    label="email"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    prepend-inner-icon="mdi-phone-classic"
                    v-model="itemModified.phone"
                    label="phone"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  class="blue lighten-3"
                  v-if="remark1Visible"
                >
                  <v-text-field
                    v-if="lang === 'id'"
                    v-model="itemModified.remark1"
                    :label="remark1Title"
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-if="lang === 'en'"
                    v-model="itemModified.remark1En"
                    :label="remark1Title"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  class="blue lighten-3"
                  v-if="remark2Visible"
                >
                  <v-text-field
                    v-if="lang === 'id'"
                    v-model="itemModified.remark2"
                    :label="remark2Title"
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-if="lang === 'en'"
                    v-model="itemModified.remark2En"
                    :label="remark2Title"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" v-if="remark3Visible">
                  <v-text-field
                    v-if="lang === 'id'"
                    v-model="itemModified.remark3"
                    :label="remark3Title"
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-if="lang === 'en'"
                    v-model="itemModified.remark3En"
                    :label="remark3Title"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6" md="6" v-if="remark4Visible">
                  <v-text-field
                    v-if="lang === 'id'"
                    v-model="itemModified.remark4"
                    :label="remark4Title"
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-if="lang === 'en'"
                    v-model="itemModified.remark4En"
                    :label="remark4Title"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="remark5Visible">
                  <v-text-field
                    v-if="lang === 'id'"
                    v-model="itemModified.remark5"
                    :label="remark5Title"
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-if="lang === 'en'"
                    v-model="itemModified.remark5En"
                    :label="remark5Title"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6" v-if="remark6Visible">
                  <v-text-field
                    v-if="lang === 'id'"
                    v-model="itemModified.remark6"
                    :label="remark6Title"
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-if="lang === 'en'"
                    v-model="itemModified.remark6En"
                    :label="remark6Title"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="remark7Visible">
                  <v-text-field
                    v-if="lang === 'id'"
                    v-model="itemModified.remark7"
                    :label="remark7Title"
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-if="lang === 'en'"
                    v-model="itemModified.remark7En"
                    :label="remark7Title"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6" md="6" v-if="remark8Visible">
                  <v-text-field
                    v-if="lang === 'id'"
                    v-model="itemModified.remark8"
                    :label="remark8Title"
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-if="lang === 'en'"
                    v-model="itemModified.remark8En"
                    :label="remark8Title"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="orange">
                <v-col cols="12" sm="6" md="6" v-if="remarkInt1Visible">
                  <v-text-field
                    v-model="itemModified.remarkInt1"
                    :label="remarkInt1Title"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="remarkInt2Visible">
                  <v-text-field
                    v-model="itemModified.remarkInt2"
                    :label="remarkInt2Title"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="blue">
                <v-col cols="12" sm="6" md="6" v-if="remarkDouble1Visible">
                  <v-text-field
                    v-model="itemModified.remarkDouble1"
                    :label="remarkDouble1Title"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="remarkDouble2Visible">
                  <v-text-field
                    v-model="itemModified.remarkDouble2"
                    :label="remarkDouble2Title"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-chip
              class="ml-4"
              color="error"
              outlined
              close
              small
              v-show="formDialogOptions.errorMessage"
            >
              {{ formDialogOptions.errorMessage }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="translateForm"
              class="hidden-sm-and-down mr-4"
              :disabled="
                lang !== 'en' &&
                (itemModified.id !== 0 || itemModified.id !== null)
              "
            >
              {{ lang === "id" ? "Terjemahkan" : "Translate" }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="closeForm"
              class="hidden-sm-and-down"
            >
              {{ lang === "id" ? "Batal" : "Cancel" }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="save"
              :disabled="!valid || isItemModified === false"
              class="hidden-sm-and-down mr-10"
            >
              {{ lang === "id" ? "Simpan" : "Save" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>

      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>

      <UploadImageDialog
        ref="refUploadDialog"
        @eventUploadSuccess="completeUploadSuccess"
      >
      </UploadImageDialog>
      <v-dialog width="100px" v-model="dialogLoading">
        <v-card class="px-4 py-7">
          <v-row justify="center">
            <v-col cols="12" align="center">
              <v-progress-circular
                :size="40"
                :width="5"
                v-if="loading"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar">
        {{ snackBarMesage }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
  </div>
</template>

<script>
import FInfrastructureService from "../../../services/apiservices/f-infrastructure-service";

import CloseConfirmDialog from "../../../components/utils/CloseConfirmDialog";
import FormMode from "../../../models/form-mode";
import FInfrastructure from "../../../models/f-infrastructure";
import FileService from "../../../services/apiservices/file-service";
import UploadImageDialog from "../../../components/utils/UploadImageDialog";
import LangTranslatorService from "@/services/apiservices/lang-translator-service";

export default {
  components: { CloseConfirmDialog, UploadImageDialog },
  props: {
    formMode: String,
    itemsFDivision: [],
    itemsFInfrastructureType: [],
    itemsFArea: [],
    itemsFSubArea: [],
  },
  data() {
    // const initLang = localStorage.getItem("lang") || "id"
    return {
      // lang: initLang,
      menu_language: [
        { id: "en", description: "English", icon: "flag_en.jpg" },
        { id: "id", description: "Indonesia", icon: "flag_id.jpg" },
      ],

      title: "INFRASTRUKTUR",
      snackBarMesage: "",
      snackbar: false,

      dialogLoading: false,
      loading: false,

      imageOverlay: false,

      dialogShow: false,
      formDialogOptions: {
        title1: "",
        title2: "",
        message1: "",
        message2: "",
        errorMessage: "",
        color: "grey lighten-3",
        width: 800,
        zIndex: 200,
        noconfirm: false,
      },

      selectedItemIndex: -1,
      itemDefault: "",
      itemModified: new FInfrastructure(0, "", ""),
      selectFDivision: { id: 0, kode1: "", description: "" },

      valid: false,
      rulesLenght: [
        (v) => !!v || " Tidak Boleh Kosong",
        (v) => v.length <= 255 || "Maksimal 255 Karakter",
        (v) => v.length > 1 || "Harus lebih besar dari 1 karakter",
      ],
      rulesNotEmtpy: [(v) => !!v || "Tidak boleh kosong"],

      remark1Title: "Jenis",
      remark2Title: "Fungsi (Klasifikasi)",
      remark3Title: "Fasilitas",
      remark4Title: "Info Lain",
      remark5Title: "Info Lain2",
      remark6Title: "Ukuran Kapal Maksimal",
      remark7Title: "Info Lain4",
      remark8Title: "Info Lain5",

      remarkInt1Title: "Jumlah orang/Pengunjung",
      remarkInt2Title: "Jumlah",
      remarkDouble1Title: "Harga",
      remarkDouble2Title: "Total",

      remark1Visible: true,
      remark2Visible: true,
      remark3Visible: true,
      remark4Visible: true,
      remark5Visible: true,

      remark6Visible: false,
      remark7Visible: false,
      remark8Visible: false,

      remarkInt1Visible: true,
      remarkInt2Visible: true,

      remarkDouble1Visible: true,
      remarkDouble2Visible: true,

      transparent: "rgba(255, 255, 255, 0)",
    };
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault);
      const modifiedItem = JSON.stringify(this.itemModified);
      return defaultItem !== modifiedItem;
    },

    itemsFSubAreaComputed() {
      return this.itemsFSubArea.filter(
        (x) => x.fareaBean === this.itemModified.fareaBean
      );
    },
    lang: {
      get() {
        return this.$store.state.langModule.lang;
      },
      set(val) {
        this.$store.dispatch("langModule/setLang", val);
      },
    },
  },
  watch: {
    itemModified: {
      handler: function (newValue) {
        if (newValue) {
          this.finfrastructureTypeChange();
        }
      },
    },
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          // this.fetchFSectorType()
        }
      },
    },
  },

  methods: {
    getFlagImage(fileName) {
      const lang = fileName;
      if (lang === undefined) {
        return require("@/assets/i18n/flag_id.jpg");
      } else {
        const langJson = this.menu_language.filter(
          (item) => item.id === `${lang}`
        );
        return require("@/assets/i18n/" + langJson[0].icon);
      }
    },
    languageChange(value) {
      this.lang = value;
      this.$i18n.locale = this.lang;
      this.finfrastructureTypeChange();
      // console.log(value)
      // localStorage.setItem("lang", value)
      /**
       * Perintah untuk Change Language
       */
      // this.$i18n.locale = localStorage.getItem("lang");
      // this.lang = localStorage.getItem("lang")
    },
    translateForm() {
      this.snackBarMesage = "";
      this.dialogLoading = true;
      this.loading = true;
      let itemFInfrastructureTranslate = [];
      let emptyValue = "-";
      if (
        this.itemModified.description !== null &&
        this.itemModified.description !== undefined &&
        this.itemModified.description !== "" &&
        this.itemModified.descriptionEn !== "-" &&
        (this.itemModified.descriptionEn === null ||
          this.itemModified.descriptionEn === undefined ||
          this.itemModified.descriptionEn === "" ||
          this.itemModified.descriptionEn === "-")
      ) {
        itemFInfrastructureTranslate.push(this.itemModified.description);
      } else {
        itemFInfrastructureTranslate.push(emptyValue);
      }
      if (
        this.itemModified.remark1 !== null &&
        this.itemModified.remark1 !== undefined &&
        this.itemModified.remark1 !== "" &&
        this.itemModified.remark1En !== "-" &&
        (this.itemModified.remark1En === null ||
          this.itemModified.remark1En === undefined ||
          this.itemModified.remark1En === "" ||
          this.itemModified.remark1En === "-")
      ) {
        itemFInfrastructureTranslate.push(this.itemModified.remark1);
      } else {
        itemFInfrastructureTranslate.push(emptyValue);
      }
      if (
        this.itemModified.remark2 !== null &&
        this.itemModified.remark2 !== undefined &&
        this.itemModified.remark2 !== "" &&
        this.itemModified.remark2En !== "-" &&
        (this.itemModified.remark2En === null ||
          this.itemModified.remark2En === undefined ||
          this.itemModified.remark2En === "" ||
          this.itemModified.remark2En === "-")
      ) {
        itemFInfrastructureTranslate.push(this.itemModified.remark2);
      } else {
        itemFInfrastructureTranslate.push(emptyValue);
      }
      if (
        this.itemModified.remark3 !== null &&
        this.itemModified.remark3 !== undefined &&
        this.itemModified.remark3 !== "" &&
        this.itemModified.remark3En !== "-" &&
        (this.itemModified.remark3En === null ||
          this.itemModified.remark3En === undefined ||
          this.itemModified.remark3En === "" ||
          this.itemModified.remark3En === "-")
      ) {
        itemFInfrastructureTranslate.push(this.itemModified.remark3);
      } else {
        itemFInfrastructureTranslate.push(emptyValue);
      }
      if (
        this.itemModified.remark4 !== null &&
        this.itemModified.remark4 !== undefined &&
        this.itemModified.remark4 !== "" &&
        this.itemModified.remark4En !== "-" &&
        (this.itemModified.remark4En === null ||
          this.itemModified.remark4En === undefined ||
          this.itemModified.remark4En === "" ||
          this.itemModified.remark4En === "-")
      ) {
        itemFInfrastructureTranslate.push(this.itemModified.remark4);
      } else {
        itemFInfrastructureTranslate.push(emptyValue);
      }
      if (
        this.itemModified.remark5 !== null &&
        this.itemModified.remark5 !== undefined &&
        this.itemModified.remark5 !== "" &&
        this.itemModified.remark5En !== "-" &&
        (this.itemModified.remark5En === null ||
          this.itemModified.remark5En === undefined ||
          this.itemModified.remark5En === "" ||
          this.itemModified.remark5En === "-")
      ) {
        itemFInfrastructureTranslate.push(this.itemModified.remark5);
      } else {
        itemFInfrastructureTranslate.push(emptyValue);
      }
      if (
        this.itemModified.remark6 !== null &&
        this.itemModified.remark6 !== undefined &&
        this.itemModified.remark6 !== "" &&
        this.itemModified.remark6En !== "-" &&
        (this.itemModified.remark6En === null ||
          this.itemModified.remark6En === undefined ||
          this.itemModified.remark6En === "" ||
          this.itemModified.remark6En === "-")
      ) {
        itemFInfrastructureTranslate.push(this.itemModified.remark6);
      } else {
        itemFInfrastructureTranslate.push(emptyValue);
      }
      if (
        this.itemModified.remark7 !== null &&
        this.itemModified.remark7 !== undefined &&
        this.itemModified.remark7 !== "" &&
        this.itemModified.remark7En !== "-" &&
        (this.itemModified.remark7En === null ||
          this.itemModified.remark7En === undefined ||
          this.itemModified.remark7En === "" ||
          this.itemModified.remark7En === "-")
      ) {
        itemFInfrastructureTranslate.push(this.itemModified.remark7);
      } else {
        itemFInfrastructureTranslate.push(emptyValue);
      }
      if (
        this.itemModified.remark8 !== null &&
        this.itemModified.remark8 !== undefined &&
        this.itemModified.remark8 !== "" &&
        this.itemModified.remark8En !== "-" &&
        (this.itemModified.remark8En === null ||
          this.itemModified.remark8En === undefined ||
          this.itemModified.remark8En === "" ||
          this.itemModified.remark8En === "-")
      ) {
        itemFInfrastructureTranslate.push(this.itemModified.remark8);
      } else {
        itemFInfrastructureTranslate.push(emptyValue);
      }

      LangTranslatorService.getPostChatGptArrayTranslateIdToEn(
        encodeURIComponent(JSON.stringify(itemFInfrastructureTranslate))
      ).then(
        (response) => {
          try {
            let itemFInfrastructureTranslateResponse = decodeURIComponent(
              response.data.translation
            )
              .replaceAll("+", " ")
              .replaceAll("=", " ")
              .trim();
            let itemFInfrastructureTranslateResponseParsed = JSON.parse(
              itemFInfrastructureTranslateResponse
            );

            if (
              (this.itemModified.descriptionEn === null ||
                this.itemModified.descriptionEn === undefined ||
                this.itemModified.descriptionEn === "" ||
                this.itemModified.descriptionEn === "-") &&
              itemFInfrastructureTranslateResponseParsed[0] != "-"
            ) {
              this.itemModified.descriptionEn =
                itemFInfrastructureTranslateResponseParsed[0];
            }
            if (
              (this.itemModified.remark1En === null ||
                this.itemModified.remark1En === undefined ||
                this.itemModified.remark1En === "" ||
                this.itemModified.remark1En === "-") &&
              itemFInfrastructureTranslateResponseParsed[1] != "-"
            ) {
              this.itemModified.remark1En =
                itemFInfrastructureTranslateResponseParsed[1];
            }
            if (
              (this.itemModified.remark2En === null ||
                this.itemModified.remark2En === undefined ||
                this.itemModified.remark2En === "" ||
                this.itemModified.remark2En === "-") &&
              itemFInfrastructureTranslateResponseParsed[2] != "-"
            ) {
              this.itemModified.remark2En =
                itemFInfrastructureTranslateResponseParsed[2];
            }
            if (
              (this.itemModified.remark3En === null ||
                this.itemModified.remark3En === undefined ||
                this.itemModified.remark3En === "" ||
                this.itemModified.remark3En === "-") &&
              itemFInfrastructureTranslateResponseParsed[3] != "-"
            ) {
              this.itemModified.remark3En =
                itemFInfrastructureTranslateResponseParsed[3];
            }
            if (
              (this.itemModified.remark4En === null ||
                this.itemModified.remark4En === undefined ||
                this.itemModified.remark4En === "" ||
                this.itemModified.remark4En === "-") &&
              itemFInfrastructureTranslateResponseParsed[4] != "-"
            ) {
              this.itemModified.remark4En =
                itemFInfrastructureTranslateResponseParsed[4];
            }
            if (
              (this.itemModified.remark5En === null ||
                this.itemModified.remark5En === undefined ||
                this.itemModified.remark5En === "" ||
                this.itemModified.remark5En === "-") &&
              itemFInfrastructureTranslateResponseParsed[5] != "-"
            ) {
              this.itemModified.remark5En =
                itemFInfrastructureTranslateResponseParsed[5];
            }
            if (
              (this.itemModified.remark6En === null ||
                this.itemModified.remark6En === undefined ||
                this.itemModified.remark6En === "" ||
                this.itemModified.remark6En === "-") &&
              itemFInfrastructureTranslateResponseParsed[6] != "-"
            ) {
              this.itemModified.remark6En =
                itemFInfrastructureTranslateResponseParsed[6];
            }
            if (
              (this.itemModified.remark7En === null ||
                this.itemModified.remark7En === undefined ||
                this.itemModified.remark7En === "" ||
                this.itemModified.remark7En === "-") &&
              itemFInfrastructureTranslateResponseParsed[7] != "-"
            ) {
              this.itemModified.remark7En =
                itemFInfrastructureTranslateResponseParsed[7];
            }
            if (
              (this.itemModified.remark8En === null ||
                this.itemModified.remark8En === undefined ||
                this.itemModified.remark8En === "" ||
                this.itemModified.remark8En === "-") &&
              itemFInfrastructureTranslateResponseParsed[8] != "-"
            ) {
              this.itemModified.remark8En =
                itemFInfrastructureTranslateResponseParsed[8];
            }
            FInfrastructureService.updateFInfrastructure(
              this.itemModified
            ).then(() => {
              console.log("Update Oke");
            });
            this.dialogLoading = false;
            this.loading = false;
            this.snackbar = true;
            if (this.lang === "id") {
              this.snackBarMesage = "Data berhasil diterjemahkan";
            }
            if (this.lang === "en") {
              this.snackBarMesage = "Data successfully translated";
            }
          } catch (error) {
            this.dialogLoading = false;
            this.loading = false;
            this.snackbar = true;
            if (this.lang === "id") {
              this.snackBarMesage = "Data yang diterjemahkan melebihi batas";
            }
            if (this.lang === "en") {
              this.snackBarMesage = "The translated data exceeds the limit";
            }
            console.log(error);
          }
        },
        (error) => {
          // console.log(" Hasil Error: " + item.description)
          console.log(error.data);
        }
      );
    },
    translateFormXX() {
      if (this.lang === "en") {
        LangTranslatorService.getPostChatGptTranslateIdToEn(
          this.itemModified.description
        ).then((response) => {
          this.itemModified.descriptionEn = response.data.translation
            .replaceAll("+", " ")
            .replaceAll("=", " ")
            .trim();
          // console.log(JSON.stringify(response.data))
        });
      }
    },

    fareaChange() {
      this.itemModified.city = this.lookupFArea(
        this.itemModified.fareaBean
      ).description;
    },
    fsubAreaChange() {
      this.itemModified.district = this.lookupFSubArea(
        this.itemModified.fsubAreaBean
      ).description;
    },

    finfrastructureTypeChange() {
      if (this.lang === "id") {
        this.remark1Title = "Jenis";
        this.remark2Title = "Fungsi (Klasifikasi)";
        this.remark3Title = "Fasilitas";
        this.remark4Title = "Info Lain1";
        this.remark5Title = "Info Lain2";

        this.remark6Title = "Info Lain3";
        this.remark7Title = "Info Lain4";
        this.remark8Title = "Info Lain5";

        this.remarkDouble1Title = "Harga";
        this.remarkDouble2Title = "Total";
        this.remarkInt1Title = "Jumlah orang/Pengunjung";
        this.remarkInt2Title = "Jumlah";
      }
      if (this.lang === "en") {
        this.remark1Title = "Type";
        this.remark2Title = "Function (Classification)";
        this.remark3Title = "Facilities";
        this.remark4Title = "Additional Info 1";
        this.remark5Title = "Additional Info 2";

        this.remark6Title = "Additional Info 3";
        this.remark7Title = "Additional Info 4";
        this.remark8Title = "Additional Info 5";

        this.remarkDouble1Title = "Price";
        this.remarkDouble2Title = "Total";
        this.remarkInt1Title = "Number of People/Visitors";
        this.remarkInt2Title = "Quantity";
      }

      this.remark1Visible = true;
      this.remark2Visible = true;
      this.remark3Visible = true;
      this.remark4Visible = true;
      this.remark5Visible = true;

      this.remark6Visible = false;
      this.remark7Visible = false;
      this.remark8Visible = false;

      this.remarkDouble1Visible = true;
      this.remarkDouble2Visible = true;

      const infraStructure = this.lookupFInfratructureType(
        this.itemModified.finfrastructureTypeBean
      );

      if (infraStructure.description.toLowerCase().includes("pelabuhan")) {
        if (this.lang === "id") {
          this.remark1Title = "Jenis Pelabuhan";
          this.remark3Title = "Fasilitas";
          this.remark4Title = "Aset yang bisa dikerjasamakan";
          this.remark5Title = "Tonase (Maks)";
          this.remark6Title = "Ukuran Kapal (Maks)";
          this.remark7Title = "Harga Sewa Aset";
          this.remark8Title = "Sumber Data";
          this.remarkInt1Title = "Jumlah Dermaga";
          this.remarkInt2Title = "Kedalaman (meter)";
        }
        if (this.lang === "en") {
          this.remark1Title = "Types of Ports";
          this.remark3Title = "Facilities";
          this.remark4Title = "Assets that can be collaboratively utilized";
          this.remark5Title = "Tonnage (Maks)";
          this.remark6Title = "Ship Size (Maks)";
          this.remark7Title = "Asset Rental Price";
          this.remark8Title = "Data Source";
          this.remarkInt1Title = "Number of Docks";
          this.remarkInt2Title = "Depth (meter)";
        }

        this.remark6Visible = true;
        this.remark7Visible = true;
        this.remark8Visible = true;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      } else if (
        infraStructure.description.toLowerCase().includes("pariwisata")
      ) {
        if (this.lang === "id") {
          this.remark1Title = "Jenis Wisata Buatan/Alam";
          this.remark3Title = "Fasilitas Hiburan/Wahana";
          this.remark7Title = "Profil Wisata";
          this.remark8Title = "Sumber Data";
          this.remarkInt1Title = "Jumlah Pengunjung per Tahun";
        }
        if (this.lang === "en") {
          this.remark1Title = "Types of Artificial/Natural Tourism";
          this.remark3Title = "Entertainment/Attractions Facilities";
          this.remark7Title = "Tourism Profile";
          this.remark8Title = "Data Source";
          this.remarkInt1Title = "Number of Visitors /year";
        }

        this.remarkInt2Visible = false;
        this.remark4Visible = false;
        this.remark2Visible = false;
        this.remark5Visible = false;
        this.remark6Visible = false;
        this.remark7Visible = true;
        this.remark8Visible = true;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      } else if (infraStructure.description.toLowerCase().includes("pasar")) {
        if (this.lang === "id") {
          this.remark1Title = "Jenis";
          this.remark8Title = "Sumber Data";
        }
        if (this.lang === "en") {
          this.remark1Title = "Type";
          this.remark8Title = "Data Source";
        }

        this.remark2Visible = false;
        this.remark3Visible = false;
        this.remark4Visible = false;
        this.remark5Visible = false;
        this.remark6Visible = false;
        this.remark7Visible = false;
        this.remark8Visible = true;

        this.remarkInt1Visible = false;
        this.remarkInt2Visible = false;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      } else if (infraStructure.description.toLowerCase().includes("bandara")) {
        if (this.lang === "id") {
          this.remark1Title = "Kelas";
          this.remark2Title = "Kategori (Nasional/Internasional)";
          this.remark3Title = "Fasilitas Bandara";
          this.remark7Title = "Profil Bandara";
          this.remark8Title = "Sumber Data";
        }
        if (this.lang === "en") {
          this.remark1Title = "Class";
          this.remark2Title = "Category (National/International)";
          this.remark3Title = "Airport Facilities";
          this.remark7Title = "Airport Profile";
          this.remark8Title = "Data Source";
        }

        this.remark2Visible = true;
        this.remark3Visible = true;
        this.remark4Visible = false;
        this.remark5Visible = false;
        this.remark6Visible = false;
        this.remark7Visible = true;
        this.remark8Visible = true;

        this.remarkInt1Visible = false;
        this.remarkInt2Visible = false;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      } else if (infraStructure.description.toLowerCase().includes("hotel")) {
        if (this.lang === "id") {
          this.remark1Title = "Kategori Bintang";
          this.remark3Title = "Fasilitas Hotel";
          this.remark8Title = "Sumber Data";
        }
        if (this.lang === "en") {
          this.remark1Title = "Star Kategori";
          this.remark3Title = "Hotel Facilities";
          this.remark8Title = "Data source";
        }

        this.remark2Visible = false;
        this.remark3Visible = true;
        this.remark4Visible = false;
        this.remark5Visible = false;
        this.remark6Visible = false;
        this.remark7Visible = false;
        this.remark8Visible = true;

        this.remarkInt1Visible = false;
        this.remarkInt2Visible = false;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      } else if (
        infraStructure.description.toLowerCase().includes("sarana pendidikan")
      ) {
        if (this.lang === "id") {
          this.remark1Title = "Jenjang";
          this.remark2Title = "Program Studi";
          this.remark3Title = "Fasilitas Pendidikan";
          this.remark5Title = "Status (Swasta/Negeri)";
          this.remark7Title = "Profil Sekolah";
          this.remark8Title = "Sumber Data";
        }
        if (this.lang === "en") {
          this.remark1Title = "Level";
          this.remark2Title = "Study Program";
          this.remark3Title = "Educational Facilities";
          this.remark5Title = "Status (Private/Public)";
          this.remark7Title = "School Profile";
          this.remark8Title = "Data Source";
        }

        this.remark2Visible = true;
        this.remark3Visible = true;
        this.remark4Visible = false;
        this.remark5Visible = true;
        this.remark6Visible = false;
        this.remark7Visible = true;
        this.remark8Visible = true;

        this.remarkInt1Visible = false;
        this.remarkInt2Visible = false;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      } else if (
        infraStructure.description.toLowerCase().includes("rumah sakit")
      ) {
        if (this.lang === "id") {
          this.remark1Title = "Kelas";
          this.remark2Title = "Jenis Pelayanan";
          this.remark3Title = "Fasilitas Pelayanan";
          this.remark5Title = "Status (Swasta/Negeri)";
          this.remark7Title = "Profil RS/Puskesmas/Klinik/dll";
          this.remark8Title = "Sumber Data";
        }
        if (this.lang === "en") {
          this.remark1Title = "Class";
          this.remark2Title = "Types of Service";
          this.remark3Title = "Service Facilities";
          this.remark5Title = "Status (Private/Public)";
          this.remark7Title = "Hospital/Clinic/Health Center/etc. Profile";
          this.remark8Title = "Data Source";
        }

        this.remark2Visible = true;
        this.remark3Visible = true;
        this.remark4Visible = false;
        this.remark5Visible = true;
        this.remark6Visible = false;
        this.remark7Visible = true;
        this.remark8Visible = true;

        this.remarkInt1Visible = false;
        this.remarkInt2Visible = false;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      } else if (
        infraStructure.description.toLowerCase().includes("perbankan")
      ) {
        if (this.lang === "id") {
          this.remark1Title = "Jenis";
          this.remark2Title = "Jenis Pelayanan";
          this.remark3Title = "Fasilitas Pelayanan";
          this.remark5Title = "Jumlah ATM dan Persebarannya";
          this.remark8Title = "Sumber Data";
        }
        if (this.lang === "en") {
          this.remark1Title = "Type";
          this.remark2Title = "Types of Service";
          this.remark3Title = "Service Facilities";
          this.remark5Title = "Number of ATMs and Distribution";
          this.remark8Title = "Data Source";
        }

        this.remark2Visible = true;
        this.remark3Visible = true;
        this.remark4Visible = false;
        this.remark5Visible = true;
        this.remark6Visible = false;
        this.remark7Visible = false;
        this.remark8Visible = true;

        this.remarkInt1Visible = false;
        this.remarkInt2Visible = false;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      } else if (
        infraStructure.description
          .toLowerCase()
          .includes("pusat administrasi kecamatan")
      ) {
        if (this.lang === "id") {
          this.remark1Title = "Jenis";
          this.remark2Title = "Jenis Pelayanan";
          this.remark3Title = "Fasilitas Pelayanan";
          this.remark7Title = "Profil Kecamatan";
          this.remark8Title = "Sumber Data";
        }
        if (this.lang === "en") {
          this.remark1Title = "Type";
          this.remark2Title = "Types of Service";
          this.remark3Title = "Service Facilities";
          this.remark7Title = "District Profile";
          this.remark8Title = "Data Source";
        }

        this.remark2Visible = true;
        this.remark3Visible = true;
        this.remark4Visible = false;
        this.remark5Visible = false;
        this.remark6Visible = false;
        this.remark7Visible = true;
        this.remark8Visible = true;

        this.remarkInt1Visible = false;
        this.remarkInt2Visible = false;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      } else if (infraStructure.description.toLowerCase().includes("mpp")) {
        if (this.lang === "id") {
          this.remark2Title = "Jenis Pelayanan";
          this.remark3Title = "Fasilitas Pelayanan";
          this.remark7Title = "Profil MPP";
          this.remark8Title = "Sumber Data";
        }
        if (this.lang === "en") {
          this.remark2Title = "Type of Services";
          this.remark3Title = "Service Facilities";
          this.remark7Title = "MPP Profile";
          this.remark8Title = "Data Source";
        }

        this.remark1Visible = false;
        this.remark2Visible = true;
        this.remark3Visible = true;
        this.remark4Visible = false;
        this.remark5Visible = false;
        this.remark6Visible = false;
        this.remark7Visible = true;
        this.remark8Visible = true;

        this.remarkInt1Visible = false;
        this.remarkInt2Visible = false;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      } else if (infraStructure.description.toLowerCase().includes("blk")) {
        if (this.lang === "id") {
          this.remark2Title = "Jenis Pelayanan";
          this.remark3Title = "Fasilitas Pelayanan";
          this.remark7Title = "Profil BLK";
          this.remark8Title = "Sumber Data";
        }
        if (this.lang === "en") {
          this.remark2Title = "Types of Service";
          this.remark3Title = "Service Facilities";
          this.remark7Title = "BLK Profile";
          this.remark8Title = "Data Source";
        }

        this.remark1Visible = false;
        this.remark2Visible = true;
        this.remark3Visible = true;
        this.remark4Visible = false;
        this.remark5Visible = false;
        this.remark6Visible = false;
        this.remark7Visible = true;
        this.remark8Visible = true;

        this.remarkInt1Visible = false;
        this.remarkInt2Visible = false;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      } else if (
        infraStructure.description
          .toLowerCase()
          .includes("pusat administrasi kabupaten")
      ) {
        if (this.lang === "id") {
          this.remark2Title = "Jenis Pelayanan";
          this.remark3Title = "Fasilitas Pelayanan";
          this.remark7Title = "Profil Kabupaten";
          this.remark8Title = "Sumber Data";
        }
        if (this.lang === "en") {
          this.remark2Title = "Types of Service";
          this.remark3Title = "Service Facilities";
          this.remark7Title = "District Profile";
          this.remark8Title = "Data Source";
        }

        this.remark1Visible = false;
        this.remark2Visible = true;
        this.remark3Visible = true;
        this.remark4Visible = false;
        this.remark5Visible = false;
        this.remark6Visible = false;
        this.remark7Visible = true;
        this.remark8Visible = true;

        this.remarkInt1Visible = false;
        this.remarkInt2Visible = false;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      } else if (
        infraStructure.description.toLowerCase().includes("stasiun kereta")
      ) {
        if (this.lang === "id") {
          this.remark1Title = "Tipe Kelas";
          this.remark2Title = "Jenis Kereta yang Berhenti di Stasiun";
          this.remark3Title = "Fasilitas Pelayanan";
          this.remark4Title = "Rute Pelayanan";
          this.remark7Title = "Pelayanan";
          this.remark8Title = "Sumber Data";
        }
        if (this.lang === "en") {
          this.remark1Title = "Class Type";
          this.remark2Title = "Types of Trains Stopping at the Station";
          this.remark3Title = "Service Facilities";
          this.remark4Title = "Service Routes";
          this.remark7Title = "Services";
          this.remark8Title = "Data Source";
        }

        this.remark1Visible = true;
        this.remark2Visible = true;
        this.remark3Visible = true;
        this.remark4Visible = true;
        this.remark5Visible = false;
        this.remark6Visible = false;
        this.remark7Visible = true;
        this.remark8Visible = true;

        this.remarkInt1Visible = false;
        this.remarkInt2Visible = false;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      } else if (infraStructure.description.toLowerCase().includes("bts")) {
        if (this.lang === "id") {
          this.remark1Title = "Tipe Kelas";
          this.remark2Title = "Nama BTS (2G/3G/4G)";
          this.remark3Title = "Operator Jaringan";
          this.remark4Title = "Rute Pelayanan";
          this.remark7Title = "Status (Swasta/Pemerintah)";
          this.remark8Title = "Sumber Data";
        }
        if (this.lang === "en") {
          this.remark1Title = "Class Type";
          this.remark2Title = "BTS Name (2G/3G/4G)";
          this.remark3Title = "Network Operator";
          this.remark4Title = "Service Routes";
          this.remark7Title = "Status (Private/Government)";
          this.remark8Title = "Data Source";
        }

        this.remark1Visible = true;
        this.remark2Visible = true;
        this.remark3Visible = true;
        this.remark4Visible = true;
        this.remark5Visible = false;
        this.remark6Visible = false;
        this.remark7Visible = true;
        this.remark8Visible = true;

        this.remarkInt1Visible = false;
        this.remarkInt2Visible = false;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      } else if (
        infraStructure.description.toLowerCase().includes("pintu tol")
      ) {
        if (this.lang === "id") {
          this.remark1Title = "Jenis";
          this.remark8Title = "Sumber Data";
        }
        if (this.lang === "en") {
          this.remark1Title = "Type";
          this.remark8Title = "Data Source";
        }

        this.remark1Visible = true;
        this.remark2Visible = false;
        this.remark3Visible = false;
        this.remark4Visible = false;
        this.remark5Visible = false;
        this.remark6Visible = false;
        this.remark7Visible = false;
        this.remark8Visible = true;

        this.remarkInt1Visible = false;
        this.remarkInt2Visible = false;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      } else if (
        infraStructure.description.toLowerCase().includes("terminal bus")
      ) {
        if (this.lang === "id") {
          this.remark1Title = "Jenis";
          this.remark8Title = "Sumber Data";
        }
        if (this.lang === "en") {
          this.remark1Title = "Type";
          this.remark8Title = "Data Source";
        }

        this.remark1Visible = true;
        this.remark2Visible = false;
        this.remark3Visible = false;
        this.remark4Visible = false;
        this.remark5Visible = false;
        this.remark6Visible = false;
        this.remark7Visible = false;
        this.remark8Visible = true;

        this.remarkInt1Visible = false;
        this.remarkInt2Visible = false;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      } else if (infraStructure.description.toLowerCase().includes("waduk")) {
        if (this.lang === "id") {
          this.remark1Title = "Tipe Kelas";
          this.remark3Title = "Fasilitas Pelayanan";
          this.remark4Title = "Debit Air";
          this.remark7Title = "Profil Waduk";
          this.remark8Title = "Sumber Data";
        }
        if (this.lang === "en") {
          this.remark1Title = "Class Type";
          this.remark3Title = "Service Facilities";
          this.remark4Title = "Water Flow";
          this.remark7Title = "Reservoir Profile";
          this.remark8Title = "Data Source";
        }

        this.remark1Visible = true;
        this.remark2Visible = false;
        this.remark3Visible = true;
        this.remark4Visible = true;
        this.remark5Visible = false;
        this.remark6Visible = false;
        this.remark7Visible = true;
        this.remark8Visible = true;

        this.remarkInt1Visible = false;
        this.remarkInt2Visible = false;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      } else if (
        infraStructure.description.toLowerCase().includes("bendungan")
      ) {
        if (this.lang === "id") {
          this.remark1Title = "Tipe Kelas";
          this.remark3Title = "Fasilitas Pelayanan";
          this.remark4Title = "Debit Air";
          this.remark7Title = "Profil Bendungan";
          this.remark8Title = "Sumber Data";
        }
        if (this.lang === "en") {
          this.remark1Title = "Class Type";
          this.remark3Title = "Service Facilities";
          this.remark4Title = "Water Flow";
          this.remark7Title = "Dam Profile";
          this.remark8Title = "Data Source";
        }

        this.remark1Visible = true;
        this.remark2Visible = false;
        this.remark3Visible = true;
        this.remark4Visible = true;
        this.remark5Visible = false;
        this.remark6Visible = false;
        this.remark7Visible = true;
        this.remark8Visible = true;

        this.remarkInt1Visible = false;
        this.remarkInt2Visible = false;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      } else if (
        infraStructure.description.toLowerCase().includes("ekspedisi")
      ) {
        if (this.lang === "id") {
          this.remark1Title = "Jenis";
          this.remark3Title = "Fasilitas Pelayanan";
          this.remark4Title = "Rute Pelayanan";
          this.remark5Title =
            "Jenis Pelayanan (melayani semua jenis brg/hewan/tumbuhan, frozenfood/alat/mesin/kendaraan/cairan/makanan/dll) ";
          this.remark7Title = "Profil ekspedisi";
          this.remark8Title = "Sumber Data";
        }
        if (this.lang === "en") {
          this.remark1Title = "Type";
          this.remark3Title = "Service Facilities";
          this.remark4Title = "Service Routes";
          this.remark5Title =
            "Types of Services (serving all types of goods/animals/plants, frozen food/tools/machines/vehicles/liquids/food/etc.)";
          this.remark7Title = "Expedition Profile";
          this.remark8Title = "Data Source";
        }

        this.remark1Visible = true;
        this.remark2Visible = false;
        this.remark3Visible = true;
        this.remark4Visible = true;
        this.remark5Visible = true;
        this.remark6Visible = false;
        this.remark7Visible = true;
        this.remark8Visible = true;

        this.remarkInt1Visible = false;
        this.remarkInt2Visible = false;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      } else if (infraStructure.description.toLowerCase().includes("pdam")) {
        if (this.lang === "id") {
          this.remark3Title = "Fasilitas Pelayanan";
          this.remark4Title = "Tarif Air per m3";
          this.remark5Title = "Jenis Pelayanan";
          this.remark7Title = "Profil PDAM";
          this.remark8Title = "Sumber Data";
        }
        if (this.lang === "en") {
          this.remark3Title = "Service Facilities";
          this.remark4Title = "Water cost /m3";
          this.remark5Title = "Types of Service";
          this.remark7Title = "PDAM Profile";
          this.remark8Title = "Data Source";
        }

        this.remark1Visible = false;
        this.remark2Visible = false;
        this.remark3Visible = true;
        this.remark4Visible = true;
        this.remark5Visible = true;
        this.remark6Visible = false;
        this.remark7Visible = true;
        this.remark8Visible = true;

        this.remarkInt1Visible = false;
        this.remarkInt2Visible = false;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      } else if (infraStructure.description.toLowerCase().includes("pln")) {
        if (this.lang === "id") {
          this.remark3Title = "Fasilitas Pelayanan";
          this.remark4Title = "Tarif Listrik per kWh";
          this.remark5Title = "Jenis Pelayanan";
          this.remark7Title = "Profil PLLN";
          this.remark8Title = "Sumber Data";
        }
        if (this.lang === "en") {
          this.remark3Title = "Service Facilities";
          this.remark4Title = "Electricity cost /kWh";
          this.remark5Title = "Types of Service";
          this.remark7Title = "PLN Profile";
          this.remark8Title = "Data Source";
        }

        this.remark1Visible = false;
        this.remark2Visible = false;
        this.remark3Visible = true;
        this.remark4Visible = true;
        this.remark5Visible = true;
        this.remark6Visible = false;
        this.remark7Visible = true;
        this.remark8Visible = true;

        this.remarkInt1Visible = false;
        this.remarkInt2Visible = false;
        this.remarkDouble1Visible = false;
        this.remarkDouble2Visible = false;
      }
    },

    showDialogUpload() {
      if (
        this.itemModified.kode1 !== "" &&
        this.itemModified.description !== "" &&
        this.itemModified.fdivisionBean !== 0 &&
        this.itemModified.finfratructureTypeBean !== 0
      ) {
        if (this.itemModified.id === 0) {
          // this.$emit('eventSaveItemWithoutClose', false)
          this.saveCreateOnly();
        }
        this.$refs.refUploadDialog.showDialog();
      } else {
        if (this.lang === "id") {
          this.snackBarMesage =
            "Kode, Deskripsi, Divisi dan Jenis Infrastruktur harus diisi dahulu";
        }
        if (this.lang === "en") {
          this.snackBarMesage =
            "Code, Description, Division and Infrastructure Type must be filled first";
        }
        this.snackbar = true;
      }
    },

    completeUploadSuccess: function (val) {
      // console.log(val)
      //Hapus Dulu yang lama
      // console.log(this.itemModified.avatarImage)

      if (
        this.itemModified.avatarImage !== undefined &&
        this.itemModified.avatarImage !== ""
      ) {
        FileService.deleteImage(this.itemModified.avatarImage).then(
          (response) => {
            console.log(response.data);
          },
          (error) => {
            console.log(error.response);
          }
        );
      }

      if (val.fileName !== "") {
        this.$refs.refUploadDialog.closeDialog();
        this.itemModified.avatarImage = val.fileName;
        this.saveUpdateOnly();
      }
    },

    showDialog(selectedIndex, item) {
      // console.log("show UserDialog " + item.id)
      // console.log(this.formMode)
      this.dialogShow = !this.dialogShow;
      if (selectedIndex > -1) {
        this.selectedIndex = selectedIndex;
        this.initializeEditMode(item);
      } else {
        this.itemDefault = new FInfrastructure(0, "", "");
        this.itemModified = new FInfrastructure(0, "", "");
        this.selectedIndex = -1;
      }
    },
    setDialogState(value) {
      this.dialogShow = value;
    },
    save() {
      if (this.isItemModified === false) {
        //Close aja
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
        return;
      }
      if (this.$refs.form.validate()) {
        if (this.formMode === FormMode.EDIT_FORM) {
          FInfrastructureService.updateFInfrastructure(this.itemModified).then(
            () => {
              // console.log(response.data)
              this.$emit("eventFromFormDialogEdit", this.itemModified);
            },
            (error) => {
              // console.log(error);
              this.formDialogOptions.errorMessage = error.response.data.message;
            }
          );
        } else {
          FInfrastructureService.createFInfrastructure(this.itemModified).then(
            (response) => {
              this.$emit("eventFromFormDialogNew", response.data);
            },
            (error) => {
              this.formDialogOptions.errorMessage = error.response.data.message;
            }
          );
        }
      }
    },
    saveCreateOnly() {
      FInfrastructureService.createFInfrastructure(this.itemModified).then(
        (response) => {
          /**
           * dipaksa Save dan Update Dahulu
           */
          // this.initializeEditMode(response.data)
          this.$emit("update:formMode", FormMode.EDIT_FORM);
          this.itemModified.id = response.data.id;
        },
        (error) => {
          this.formDialogOptions.errorMessage = error.response.data.message;
        }
      );
    },
    saveUpdateOnly() {
      FInfrastructureService.updateFInfrastructure(this.itemModified).then(
        () => {},
        (error) => {
          // console.log(error);
          this.formDialogOptions.errorMessage = error.response.data.message;
        }
      );
    },
    closeForm() {
      if (!this.isItemModified) {
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
      } else {
        if (this.lang === "id") {
          this.$refs.refCloseConfirmDialog.showDialog(
            " Sudah terdapat modifikasi data",
            "Tetap tutup dan reset perubahan?"
          );
        }
        if (this.lang === "en") {
          this.$refs.refCloseConfirmDialog.showDialog(
            " There are already modifications to the data",
            "Still, want to close and reset the changes?"
          );
        }
      }
    },
    passingEventFromCloseConfirm(value) {
      if (value === "OKE") this.dialogShow = false;
    },
    initializeEditMode(item) {
      // this.itemDefault = item
      // this.itemModified = item

      this.formDialogOptions.errorMessage = "";

      FInfrastructureService.getFInfrastructureById(item.id).then(
        (response) => {
          console.log(response.data);
          this.itemDefault = Object.assign({}, response.data);
          this.itemModified = response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    lookupFDivision(fdivisionBean) {
      const str = this.itemsFDivision.filter((x) => x.id === fdivisionBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    lookupFInfratructureType(finfratructureTypeBean) {
      const str = this.itemsFInfrastructureType.filter(
        (x) => x.id === finfratructureTypeBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupFSubArea(fsubAreaBean) {
      const str = this.itemsFSubArea.filter((x) => x.id === fsubAreaBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    lookupFArea(fareaBean) {
      const str = this.itemsFArea.filter((x) => x.id === fareaBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    lookupImageUrl(item) {
      // console.log(JSON.stringify(item))

      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_medium(item.avatarImage);
      }
    },
    lookupImageUrlLazy(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_verylow(item.avatarImage);
      }
    },
  },
};
</script>

<style scoped>
.show-btns {
  color: blue !important;
  opacity: 1;
}
</style>