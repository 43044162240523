<template>
  <v-card class="elevation-0">

    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>

        <v-text-field
            v-on:keyup.enter="searchOnEnter"
            v-on:blur="searchOnEnter"
            append-icon="mdi-magnify"
            hint="Kode, Deskripsi (press ENTER to search)"
            label="Search"
        ></v-text-field>
        <v-btn
            @click="showFilterDialog"
            icon fab
            color="blue"
            small
            class="ml-2"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>

    </v-card-title>

    <v-expand-transition>
      <v-card class="ml-2 mr-2 elevation-0 grey lighten-4 " v-show="showFilter===true">
        <v-card-text>

          <v-row>
            <v-col
                cols="12"
                sm="4"
                md="4"
            >
              <v-autocomplete
                  v-model="filterFdivisions"
                  :items="itemsFDivision"
                  item-value="id"
                  item-text="description"
                  dense
                  chips
                  small-chips
                  deletable-chips
                  clearable
                  :label="lang==='id'? 'Divisi':'Division'"
                  multiple
                  hide-details
              ></v-autocomplete>
            </v-col>
            <v-col
                cols="12"
                sm="4"
                md="4"
            >
              <v-autocomplete
                  v-model="filterFinfrastructureType"
                  :items="itemsFInfrastructureType"
                  item-value="id"
                  :item-text="lang==='id'? 'description' : 'descriptionEn'"
                  dense
                  chips
                  small-chips
                  deletable-chips
                  clearable
                  :label="lang==='id'? 'Tipe Infrastruktur': 'Infrastructure Type'"
                  multiple
                  hide-details
              ></v-autocomplete>
            </v-col>
            <v-col
                cols="12"
                sm="4"
                md="4"
            >
              <v-autocomplete
                  v-model="filterFareaBean"
                  :items="itemsFArea"
                  item-value="id"
                  item-text="description"
                  dense
                  small-chips
                  hide-details
                  :label="lang==='id'? 'Kabupaten/Kota':'District/City'"
                  class="ml-1 mr-1"
                  multiple
                  clearable
              ></v-autocomplete>
            </v-col>

          </v-row>

          <v-row>

            <v-col cols="6" sm="2" md="2">
              <v-btn color="primary" small @click="runExtendedFilter">
                {{lang==='id'?'Terapkan':'Apply'}}
                <v-icon small class="ml-1" color="green lighten-2">mdi-filter</v-icon>
              </v-btn>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-expand-transition>

    <v-data-table
        v-model="selectedItems"
        :single-select="!multiSelect"
        :show-select="multiSelect"
        :headers="headers"
        :items="fInfrastructuresFiltered"
        :page.sync="currentPage"
        :items-per-page="pageSize"
        hide-default-footer
        class="elevation-0"
        @page-count="totalTablePages =totalPaginationPages "
    >
      <template v-slot:top>
        <v-row align="center" class="ml-4 mr-4">
          <v-switch
              v-model="multiSelect"
              :label="multiSelect?'Multi Select':'Single Select'"
              x-small
              class="pa-3"
          ></v-switch>
          <v-btn
              fab
              dark
              color="red accent-4"
              x-small
              :disabled="!multiSelect"
              class="mr-2"
              @click="deleteDialogMultiShow"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-menu offset-y style="align-items: start">
            <template v-slot:activator="{ on, attrs }" >
              <v-btn
                  fab
                  dark
                  x-small
                  color="blue"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>mdi-view-grid</v-icon>
              </v-btn>

            </template>
            <v-list color="grey lighten-4" class="mr" >
              <v-list-item>
                <v-btn
                    plain
                    elevation="0"
                    color="gray darken-1"
                    small
                    @click="importDialogShow"
                >
                  <v-icon color="green">mdi-upload</v-icon>
                  Import from Template
                </v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn
                    plain
                    elevation="0"
                    color="gray darken-1"
                    small
                    @click="importExtDialogShow"
                >
                  <v-icon color="red">mdi-upload</v-icon>
                  Import from Template Extended
                </v-btn>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item>
                <v-btn
                    plain
                    elevation="0"
                    color="gray darken-1"
                    small
                    @click="exportData"
                >
                  <v-icon color="green" small>mdi-file-excel</v-icon>
                  Export Excel
                </v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn
                    class="mx-1"
                    plain
                    elevation="0"
                    color="gray darken-1"
                    small
                    @click="translateDialogShow"
                  
                >
                  <v-icon color="red">mdi-translate</v-icon>
                  Translate to English
                </v-btn>
              </v-list-item>
            </v-list>

          </v-menu>

          <v-btn
              fab
              dark
              color="green"
              x-small
              @click="showDialogNew"
              :disabled="multiSelect"
              class="ml-1"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>


      <template v-slot:[`item.statusActive`]="{ item }">
        <v-chip
            :color="getColorStatusActive(item.statusActive)"
            dark
            small
        >
          {{ item.statusActive==true?'Aktif': '-' }}
        </v-chip>
      </template>

      <template v-slot:[`item.number`]="{ index }">
        {{ (index + ((currentPage-1)*pageSize) +1 )}}
      </template>

      <template v-slot:[`item.avatar`]="{ item}">
          <v-img
              :lazy-src="lookupImageUrlLazy(item)"
              :src="lookupImageUrl(item)"
              alt="avatar"
              width="70px"
              height="70px"
              class="ma-2 rounded"
          >
          </v-img>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <div class="subtitle-1" v-if="lang==='id'">
          {{item.description}}
        </div>
        <div class="subtitle-1" v-if="lang==='en'">
          {{item.descriptionEn}}
        </div>
      </template>

      <template v-slot:[`item.finfrastructureTypeBean`]="{ item }">
        <div  class="text-caption small" v-if="lang==='id'">
          {{ lookupFInfratructureType(item.finfrastructureTypeBean).description }}
        </div>
        <div  class="text-caption small" v-if="lang==='en'">
          {{ lookupFInfratructureType(item.finfrastructureTypeBean).descriptionEn }}
        </div>
        <div>
          {{item.tahun}}
        </div>
      </template>

      <template v-slot:[`item.fsubAreaBean`]="{ item }">
        <div  class="text-caption small">
          {{ lookupFSubArea(item.fsubAreaBean).description }}
        </div>
        <div  class="text-caption small">
          {{ lookupFArea(item.fareaBean).description }}
        </div>
      </template>

      <template v-slot:[`item.fdivisionBean`]="{ item }">
          <div  class="text-caption small">
            {{ lookupFDivision(item.fdivisionBean).description }}
          </div>
          <div>
            <v-chip
                :color="getColorStatusActive(item.statusActive)"
                dark
                x-small
            >
              {{ item.statusActive==true?'Aktif': '-' }}
            </v-chip>
          </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            @click="showDialogEdit(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="warning"
          >
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
            @click="deleteDialogShow(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="red accent-4"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>

    <v-container>
      <v-row justify="end" align="center">
        <v-col
        cols="4"
        md="2"
        sm="2"
        >
          <v-select
              v-model="pageSize"
              :items="pageSizes"
              label="Items per page"
          ></v-select>
        </v-col>
        <v-col
            cols="10"
            md="9"
            sm="8"
            align="right"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
              total-visible="8"
              circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <DeleteConfirmDialog
        ref="refDeleteConfirmDialog"
        @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
        @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></DeleteConfirmDialog>

    <FInfrastructureDialog
          :formMode.sync="formMode"
          :itemsFDivision = "itemsFDivision"
          :itemsFInfrastructureType = "itemsFInfrastructureType"
          :itemsFArea="itemsFArea"
          :itemsFSubArea="itemsFSubArea"
          ref="refFormDialog"
          @eventFromFormDialogNew="saveDataNew"
          @eventFromFormDialogEdit="saveDataEdit"
    ></FInfrastructureDialog>

    <FInfrastructureImportDialog
        :itemsFDivision = "itemsFDivision"
        :itemsFInfrastructureType="itemsFInfrastructureType"
        :itemsFArea="itemsFArea"
        :itemsFSubArea="itemsFSubArea"
        ref="refImportDialog"
        @importExcelCompleted="importExcelCompleted"
    ></FInfrastructureImportDialog>
    <FInfrastructureImportExtDialog
        :itemsFDivision = "itemsFDivision"
        :itemsFInfrastructureType="itemsFInfrastructureType"
        :itemsFArea="itemsFArea"
        :itemsFSubArea="itemsFSubArea"
        ref="refImportExtDialog"
        @importExcelCompleted="importExcelCompleted"
    ></FInfrastructureImportExtDialog>
    <ConfirmDialog
        ref="refConfirmDialog"
        @eventFromOkeConfirmDialog="confirmDialogOke"
    ></ConfirmDialog>
    <FInfrastructureImportPasarDialog
        :itemsFDivision = "itemsFDivision"
        :itemsFInfrastructureType="itemsFInfrastructureType"
        :itemsFArea="itemsFArea"
        :itemsFSubArea="itemsFSubArea"
        ref="refImportDialogPasar"
        @importExcelCompleted="importExcelCompleted"
    ></FInfrastructureImportPasarDialog>

    <v-dialog width="100px" v-model="dialogLoading" >
      <v-card class="px-4 py-7">
        <v-row justify="center">
          <v-col cols="12" align="center">
            <v-progress-circular
              :size="40"
              :width="5"
              v-if="loading"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>

<script>
import FInfrastructureService from '../../../services/apiservices/f-infrastructure-service';
import FDivisionService from "../../../services/apiservices/f-division-service";
import FInfrastructureTypeService from "../../../services/apiservices/f-infrastructure-type-service";
import ConfirmDialog from "@/components/utils/ConfirmDialog.vue";

import DeleteConfirmDialog from "../../utils/DeleteConfirmDialog";
import FInfrastructureDialog from "./FInfrastructureDialog";
import FInfrastructureImportDialog from "@/components/admin_infra/infra/FInfrastructureImportDialog";
import FInfrastructureImportPasarDialog from "@/components/admin_infra/infra/FInfrastructureImportPasarDialog";

import FormMode from "../../../models/form-mode";
import FInfrastructure from '../../../models/f-infrastructure'
import FileService from "../../../services/apiservices/file-service";
import FInfrastructureFilter from "@/models/payload/f-infrastructure-filter";
import FSubAreaService from "@/services/apiservices/f-sub-area-service";
import FAreaService from "@/services/apiservices/f-area-service";
import FInfrastructureImportExtDialog from "@/components/admin_infra/infra/FInfrastructureImportExtDialog";
import LangTranslatorService from "@/services/apiservices/lang-translator-service";//import LangTranslatorService from "@/services/apiservices/lang-translator-service"; import AzureTranslateService frimport LangTranslatorService from "@/services/apiservices/lang-translator-service";om "@/services/apiservices/azure-translate-service";


import ESchemaSalesCs from "@/models/e-schema-sales-cs";
import writeXlsxFile from 'write-excel-file'
import FileSaver from 'file-saver'


export default {
  components: {
    FInfrastructureImportExtDialog,
    FInfrastructureDialog,
    FInfrastructureImportDialog,
    FInfrastructureImportPasarDialog,
    DeleteConfirmDialog,
    ConfirmDialog
  },
  data () {
    return {
      title: 'INFRASTRUKTUR',
      snackbar: false,
      snackBarMesage: '',

      dialogLoading: false,
      loading: false,

      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500, 1500],

      showFilter: false,
      filterFdivisions: [],
      filterFinfrastructureType: [],
      filterFareaBean: [],

      search: '',

      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'avatar',
        },
        { text: 'Deskripsi', value: 'description', width:"30%" },
        { text: 'Jenis', value: 'finfrastructureTypeBean' },
        { text: 'Kec-Kab/Kota', value: 'fsubAreaBean' },
        { text: 'Division', value: 'fdivisionBean' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],

      formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      fInfrastructures:[
        new FInfrastructure(0, '','')
      ],
      itemsFDivision: [
        { id: 0, kode1: '', description: '' },
      ],
      itemsFInfrastructureType:[],
      itemsFArea:[],
      itemsFSubArea:[],

    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFInfrastructure()
      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage==1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)
          this.fetchFInfrastructure()
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      }
    },
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFSectorType()
        }
      }
    },
    
  },
  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    fInfrastructuresFiltered(){
      return this.fInfrastructures
    },
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
  },
  methods: {
    showFilterDialog(){
      this.showFilter = !this.showFilter
    },

    runExtendedFilter(){

      const extendedFilter = new FInfrastructureFilter()

      if (this.filterFdivisions.length >0){
        extendedFilter.fdivisionIds = this.filterFdivisions
      }else {
        extendedFilter.fdivisionIds = this.filterFdivisions.map( item => item.id)
      }

      if (this.filterFinfrastructureType.length >0){
        extendedFilter.finfrastructureTypeIds = this.filterFinfrastructureType
      }else {
        extendedFilter.finfrastructureTypeIds = this.itemsFInfrastructureType.map( item => item.id)
      }

      extendedFilter.fareaIds = this.filterFareaBean

      if (extendedFilter.fdivisionIds.length ==0) extendedFilter.fdivisionIds = []
      if (extendedFilter.fareaIds.length ==0) extendedFilter.fareaIds = []
      if (extendedFilter.finfrastructureTypeIds.length ==0) extendedFilter.finfrastructureTypeIds = []

      // console.log(`>> ${JSON.stringify(extendedFilter.finfrastructureTypeIds)}`)

      extendedFilter.pageNo = this.currentPage
      extendedFilter.pageSize = this.pageSize
      extendedFilter.sortBy = "id"
      extendedFilter.order = "DESC"
      extendedFilter.search = this.search

      extendedFilter.city = ""

      FInfrastructureService.getPostAllFInfrastructureContainingExt(extendedFilter).then(
          response =>{
            const { items, totalPages} = response.data
            this.fInfrastructures = items
            this.totalPaginationPages = totalPages

          },
          error =>{
            console.log(error)
          }
      )

    },

    searchOnEnter(event){
      if (this.search !== event.target.value) { //Krusial untuk search
        // console.log(`${event.target.value} vs ${this.search}`)
        this.currentPage = 1
        this.search = event.target.value
        this.fetchFInfrastructure()
      }
    },

    importDialogShow(){
      this.$refs.refImportDialog.showDialog()
      // console.log("hello bos")
    },
    importExtDialogShow(){
      this.$refs.refImportExtDialog.showDialog()
      // console.log("hello bos")
    },
    importDialogPasarShow(){
      this.$refs.refImportDialogPasar.showDialog()
      // console.log("hello bos")
    },

    importExcelCompleted(value){
      if (value ==='OKE'){
        this.fetchFInfrastructure()
      }
    },

    exportData(){

      let schema = ESchemaSalesCs

      let fileBits = 'file_infrastruktur.xlsx'

      let ftDataSorted = this.fInfrastructures.slice().sort(function(a, b){
        return (a.city > b.city) ? 1 : -1;
      });

      let ftDataToShowReports = []
      ftDataSorted.forEach( item => {
        let ftData = item
        ftData.fdivision = this.lookupFDivision(ftData.fdivisionBean)

        ftDataToShowReports.push(ftData)

      })

      writeXlsxFile(ftDataToShowReports, {
        schema,
        fileName: fileBits
      })

      let file = new File(fileBits, "file_data.txt", {type: "xlsx/plain;charset=utf-8"});
      FileSaver.saveAs(file);

    },

    fetchParent(){
      if (this.currentUser.organizationLevel === "DIV") {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
            response=>{
              this.itemsFDivision = [response.data]
              // console.log(response.data.items)
            },
            error=>{
              console.log(error.response)
            }
        )

      }else {
        FDivisionService.getAllFDivision().then(
            response => {
              // console.log(JSON.stringify(response.data))
              this.itemsFDivision = response.data
            },
            error => {
              console.log(error.response)
            }
        )
      }

      FInfrastructureTypeService.getAllFInfrastructureType().then(
          response => {
            // console.log(JSON.stringify(response.data))
            this.itemsFInfrastructureType = response.data
          },
          error => {
            console.log(error.response)
          }
      )
      FSubAreaService.getAllFSubArea().then(
          response => {
            this.itemsFSubArea = response.data
          },
          error => {
            console.log(error)
          }
      )
      FAreaService.getAllFArea().then(
          response => {
            // console.log(JSON.stringify(response.data))
            this.itemsFArea = response.data
          },
          error => {
            console.log(error.response)
          }
      )


    },
    fetchFInfrastructure() {
      if (this.showFilter){
        this.runExtendedFilter()
      }else {
        FInfrastructureService.getAllFInfrastructureContaining(this.currentPage, this.pageSize, "id", "DESC", this.search).then(
            response => {
              // console.log(response.data.items)
              const { items, totalPages} = response.data
              this.fInfrastructures = items
              this.totalPaginationPages = totalPages
              // console.log(`TotalPage ${totalPages} and TotalItems ${items} `)
            },
            error => {
              console.log(error.response)
            }
        )
      }

    },
    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },
    translateDialogShow(){
      this.$refs.refConfirmDialog.showDialog("Yakin akan Membuat Terjemahan  Data Dalam Daftar? ", "(Yang belum mempunyai terjemahan)")
    },
    //DUAL BAHASA
    confirmDialogOke(){
      this.snackBarMesage = ''
      this.dialogLoading = true
      this.loading = true
      let itemsFInfrastructureTranslate = []
      let itemsFInfrastructureSorted = []
      this.fInfrastructures.sort((a, b) => a.id > b.id ? 1 : -1).forEach((item) => {
        let itemFInfrastructureTranslate = []
        let emptyValue = "-";
        if ((item.description !== null && item.description !== undefined && item.description !== "" &&item.description !== "-") && (item.descriptionEn === null || item.descriptionEn === undefined ||item.descriptionEn === "" || item.descriptionEn === "-")) {
          itemFInfrastructureTranslate.push(item.description)
        } else {
          itemFInfrastructureTranslate.push(emptyValue);
        }
        if ((item.remark1 !== null && item.remark1 !== undefined && item.remark1 !== "" &&item.remark1 !== "-") && (item.remark1En === null || item.remark1En === undefined ||item.remark1En === "" || item.remark1En === "-")) {
          itemFInfrastructureTranslate.push(item.remark1)
        } else {
          itemFInfrastructureTranslate.push(emptyValue);
        }
        if ((item.remark2 !== null && item.remark2 !== undefined && item.remark2 !== "" &&item.remark2 !== "-") && (item.remark2En === null || item.remark2En === undefined ||item.remark2En === "" || item.remark2En === "-")) {
          itemFInfrastructureTranslate.push(item.remark2)
        } else {
          itemFInfrastructureTranslate.push(emptyValue);
        }
        if ((item.remark3 !== null && item.remark3 !== undefined && item.remark3 !== "" &&item.remark3 !== "-") && (item.remark3En === null || item.remark3En === undefined ||item.remark3En === "" || item.remark3En === "-")) {
          itemFInfrastructureTranslate.push(item.remark3)
        } else {
          itemFInfrastructureTranslate.push(emptyValue);
        }
        if ((item.remark4 !== null && item.remark4 !== undefined && item.remark4 !== "" &&item.remark4 !== "-") && (item.remark4En === null || item.remark4En === undefined ||item.remark4En === "" || item.remark4En === "-")) {
          itemFInfrastructureTranslate.push(item.remark4)
        } else {
          itemFInfrastructureTranslate.push(emptyValue);
        }
        if ((item.remark5 !== null && item.remark5 !== undefined && item.remark5 !== "" &&item.remark5 !== "-") && (item.remark5En === null || item.remark5En === undefined ||item.remark5En === "" || item.remark5En === "-")) {
          itemFInfrastructureTranslate.push(item.remark5)
        } else {
          itemFInfrastructureTranslate.push(emptyValue);
        }
        if ((item.remark6 !== null && item.remark6 !== undefined && item.remark6 !== "" &&item.remark6 !== "-") && (item.remark6En === null || item.remark6En === undefined ||item.remark6En === "" || item.remark6En === "-")) {
          itemFInfrastructureTranslate.push(item.remark6)
        } else {
          itemFInfrastructureTranslate.push(emptyValue);
        }
        if ((item.remark7 !== null && item.remark7 !== undefined && item.remark7 !== "" &&item.remark7 !== "-") && (item.remark7En === null || item.remark7En === undefined ||item.remark7En === "" || item.remark7En === "-")) {
          itemFInfrastructureTranslate.push(item.remark7)
        } else {
          itemFInfrastructureTranslate.push(emptyValue);
        }
        if ((item.remark8 !== null && item.remark8 !== undefined && item.remark8 !== "" &&item.remark8 !== "-") && (item.remark8En === null || item.remark8En === undefined ||item.remark8En === "" || item.remark8En === "-")) {
          itemFInfrastructureTranslate.push(item.remark8)
        } else {
          itemFInfrastructureTranslate.push(emptyValue);
        }
        itemsFInfrastructureTranslate.push(itemFInfrastructureTranslate)
        itemsFInfrastructureSorted.push(item)
      })
      console.log(itemsFInfrastructureTranslate)
      // console.log(JSON.stringify(itemsFInfrastructureSorted))
      LangTranslatorService.getPostChatGptArrayTranslateIdToEn(encodeURIComponent(JSON.stringify(itemsFInfrastructureTranslate))).then(
        (response) => {
          try{
            let itemsFInfrastructureTranslateResponse = decodeURIComponent(response.data.translation).replaceAll("+", " ").replaceAll("=", " ").trim()
            let itemsFInfrastructureTranslateResponseParsed = JSON.parse(itemsFInfrastructureTranslateResponse)
              itemsFInfrastructureSorted.forEach((itemFInfrastructureSorted,index)=> {
                if ((itemFInfrastructureSorted.descriptionEn === null || itemFInfrastructureSorted.descriptionEn === undefined || itemFInfrastructureSorted.descriptionEn === "" || itemFInfrastructureSorted.descriptionEn === "-") && itemsFInfrastructureTranslateResponseParsed[index][0] !== "-") {
                  itemFInfrastructureSorted.descriptionEn = itemsFInfrastructureTranslateResponseParsed[index][0]
                }
                if ((itemFInfrastructureSorted.remark1En === null || itemFInfrastructureSorted.remark1En === undefined || itemFInfrastructureSorted.remark1En === "" || itemFInfrastructureSorted.remark1En === "-") && itemsFInfrastructureTranslateResponseParsed[index][1] !== "-") {
                  itemFInfrastructureSorted.remark1En = itemsFInfrastructureTranslateResponseParsed[index][1]
                }
                if ((itemFInfrastructureSorted.remark2En === null || itemFInfrastructureSorted.remark2En === undefined || itemFInfrastructureSorted.remark2En === "" || itemFInfrastructureSorted.remark2En === "-") && itemsFInfrastructureTranslateResponseParsed[index][2] !== "-") {
                  itemFInfrastructureSorted.remark2En = itemsFInfrastructureTranslateResponseParsed[index][2]
                }
                if ((itemFInfrastructureSorted.remark3En === null || itemFInfrastructureSorted.remark3En === undefined || itemFInfrastructureSorted.remark3En === "" || itemFInfrastructureSorted.remark3En === "-") && itemsFInfrastructureTranslateResponseParsed[index][3] !== "-") {
                  itemFInfrastructureSorted.remark3En = itemsFInfrastructureTranslateResponseParsed[index][3]
                }
                if ((itemFInfrastructureSorted.remark4En === null || itemFInfrastructureSorted.remark4En === undefined || itemFInfrastructureSorted.remark4En === "" || itemFInfrastructureSorted.remark4En === "-") && itemsFInfrastructureTranslateResponseParsed[index][4] !== "-") {
                  itemFInfrastructureSorted.remark4En = itemsFInfrastructureTranslateResponseParsed[index][4]
                }
                if ((itemFInfrastructureSorted.remark5En === null || itemFInfrastructureSorted.remark5En === undefined || itemFInfrastructureSorted.remark5En === "" || itemFInfrastructureSorted.remark5En === "-") && itemsFInfrastructureTranslateResponseParsed[index][5] !== "-") {
                  itemFInfrastructureSorted.remark5En = itemsFInfrastructureTranslateResponseParsed[index][5]
                }
                if ((itemFInfrastructureSorted.remark6En === null || itemFInfrastructureSorted.remark6En === undefined || itemFInfrastructureSorted.remark6En === "" || itemFInfrastructureSorted.remark6En === "-") && itemsFInfrastructureTranslateResponseParsed[index][6] !== "-") {
                  itemFInfrastructureSorted.remark6En = itemsFInfrastructureTranslateResponseParsed[index][6]
                }
                if ((itemFInfrastructureSorted.remark7En === null || itemFInfrastructureSorted.remark7En === undefined || itemFInfrastructureSorted.remark7En === "" || itemFInfrastructureSorted.remark7En === "-") && itemsFInfrastructureTranslateResponseParsed[index][7] !== "-") {
                  itemFInfrastructureSorted.remark7En = itemsFInfrastructureTranslateResponseParsed[index][7]
                }
                if ((itemFInfrastructureSorted.remark8En === null || itemFInfrastructureSorted.remark8En === undefined || itemFInfrastructureSorted.remark8En === "" || itemFInfrastructureSorted.remark8En === "-") && itemsFInfrastructureTranslateResponseParsed[index][8] !== "-") {
                  itemFInfrastructureSorted.remark8En = itemsFInfrastructureTranslateResponseParsed[index][8]
                }
                console.log(itemFInfrastructureSorted)
                FInfrastructureService.updateFInfrastructure(itemFInfrastructureSorted).then(
                  ()=>{
                    console.log('Update Oke')
                  }
                )
              })
              this.dialogLoading = false;
              this.loading = false
              this.snackbar = true
              if(this.lang==='id'){
                this.snackBarMesage = 'Data berhasil diterjemahkan'
              }
              if(this.lang==='en'){
                this.snackBarMesage = 'Data successfully translated'
              }
              this.$refs.refConfirmDialog.setDialogState(false)
          }
          catch(error){
            this.dialogLoading = false;
            this.loading = false
            this.snackbar = true
            if(this.lang==='id'){
              this.snackBarMesage = 'Data yang diterjemahkan melebihi batas'
            }
            if(this.lang==='en'){
              this.snackBarMesage = 'The translated data exceeds the limit'
            }
            this.$refs.refConfirmDialog.setDialogState(false)
            console.log(error)
          }
        },
        (error) => {
          // console.log(" Hasil Error: " + item.description)
          console.log(error.data);
        }
      );
    },
    showDialogNew() {

      this.itemSelectedIndex =-1
      const itemModified = Object.assign({}, '')
      // this.formDialogShow = true
      this.formMode = FormMode.NEW_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified)
    },
    saveDataNew(itemFromRest){
      this.itemSelected = itemFromRest
      this.closeDialog()
      /**
       * Setiap data baru harus terlihat
       */
      // this.fInfrastructures.push(this.itemSelected)
      // this.fInfrastructures = [itemFromRest].concat(this.fInfrastructures)
      // this.fInfrastructures.unshift(itemFromRest)
      this.fetchFInfrastructure()
    },

    showDialogEdit (item) {
      this.itemSelectedIndex = this.fInfrastructuresFiltered.indexOf(item)
      const itemModified = Object.assign({}, item)
      // this.formDialogShow = true
      this.formMode = FormMode.EDIT_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision)
    },
    saveDataEdit(item){
        this.itemSelected = item
        if (item.lang !== 'id'){
          this.fetchFInfrastructure()
        }else {
          try {
            Object.assign(this.fInfrastructures[this.itemSelectedIndex], this.itemSelected)
          }catch (e) {
              this.fetchFInfrastructure()
              e.toString()
          }
        }
        this.closeDialog()
    },

    deleteDialogShow (item) {
      // console.log(item)
      this.itemSelectedIndex = this.fInfrastructuresFiltered.indexOf(item)
      this.itemSelected = Object.assign({}, item)
      // this.myConfirmDelete = true
      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, item.username)
    },
    deleteDialogMultiShow(){
      if (this.multiSelect===true){
        // console.log(`nilai ${this.selectedItems}`)
        this.$refs.refDeleteConfirmDialog.showDialogMulti(this.selectedItems, `${this.selectedItems.length} items selected`)
      }
    },
    deleteItemConfirmedSingleSelect(index){
      console.log("delete SingleItem: " + index)

      const deletedItem = this.fInfrastructures[this.itemSelectedIndex]
      FInfrastructureService.deleteFInfrastructure(deletedItem.id).then(
        () => {
          // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
          this.fInfrastructures.splice(this.itemSelectedIndex, 1)
          this.closeDialog()
        },
        error => {
          console.log(error)
          this.snackBarMesage = 'gagal hapus (digunakan oleh data anakk)'
          this.snackbar = true
          this.$refs.refDeleteConfirmDialog.setDialogState(false)
        }
      )
    },
    deleteItemConfirmedMultiSelect(items){
      // console.log(okeMessage)
      if (items.length >-1){
        let itemIds = []
        for (let i=0; i<items.length; i++){
            itemIds.push(items[i].id)
        }
        // console.log("Item idex: " + itemIds)

        FInfrastructureService.deleteAllFInfrastructure(itemIds).then(
            response => {

              console.log(response.data)

              if (this.showFilter){
                this.runExtendedFilter()
              }else {
                this.fetchFInfrastructure()
              }
              this.selectedItems = []
              this.closeDialog()
            },
            error => {
              console.log("error " + error)
            }
        )

        // console.log(items)
        // FInfrastructureService.deleteAllFInfrastructures()
      }

    },
    closeDialog () {
      // this.myConfirmDialog = false
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FInfrastructure())
        this.itemSelectedIndex = -1
      })
    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFInfratructureType (finfratructureTypeBean) {
      const str = this.itemsFInfrastructureType.filter(x => x.id===finfratructureTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFSubArea (fsubAreaBean) {
      const str = this.itemsFSubArea.filter(x => x.id===fsubAreaBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFArea (fareaBean) {
      const str = this.itemsFArea.filter(x => x.id===fareaBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }else {
      this.fetchParent()
      this.fetchFInfrastructure()

    }
  }

}
</script>

<style scoped>

</style>