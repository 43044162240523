<template>

  <v-card class="mt-2 ml-2 mr-2">
    <FInfrastructureTable ref="refTabInfrastructureClick"/>
  </v-card>

</template>

<script>
import FInfrastructureTable from "../../components/admin_infra/infra/FInfrastructureTable";

export default {
  components: { FInfrastructureTable },
  name: 'AdminInfrastructureView',
  data() {
    return {
      firstTabTitle: 'INFRASTRUKTUR',
      firstTabIcon: 'mdi-factory'
    }
  },
  methods: {
    tabInfrastructureClick(){
      try {
        this.$refs.refTabInfrastructureClick.fetchParent()
      }catch (e) {
        e.toString()
      }
    },

  }
}
</script>

<style scoped>

</style>