<template>
  <div class="align-self-center">
    <v-dialog
      v-model="dialogShow"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeDialog"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-toolbar class="grey lighten-3" elevation="0" dense>
          <v-toolbar-title>Read File Excel</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="red" icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="justify-center">
          <v-row justify="center" align="center">
            <v-col cols="5">
              <v-file-input
                v-model="currentFile"
                show-size
                counter
                label="Pilih File Excel"
                accept=".xls,.xlsx"
                @change="selectFile"
              ></v-file-input>
            </v-col>
            <v-col cols="3" class="pl-1">
              <v-autocomplete
                v-model="finfrastructureTypeBeanSelected"
                :rules="rulesNotEmtpy"
                :items="itemsFInfrastructureType"
                item-value="id"
                item-text="description"
                auto-select-first
                dense
                chips
                deletable-chips
                color="blue-grey lighten-2"
                label="Jenis Infras"
                hint="Jenis Infras"
                persistent-hint
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" class="pl-1">
              <v-autocomplete
                v-model="fdivisionBeanSelected"
                :items="itemsFDivision"
                item-value="id"
                item-text="description"
                dense
                chips
                deletable-chips
                color="blue-grey lighten-2"
                label="Pemilik Data (Kab/Kota)"
                hint="Kosong, maka akan disesuikan dengan kab/kota masing2"
                persistent-hint
              ></v-autocomplete>
            </v-col>

            <v-col cols="2" class="pl-2">
              <v-btn
                color="success"
                small
                @click="startUpload"
                :disabled="
                  !currentFile ||
                  finfrastructureTypeBeanSelected === 0 ||
                  finfrastructureTypeBeanSelected === null ||
                  fdivisionBeanSelected === 0 ||
                  fdivisionBeanSelected === null ||
                  sudahUpload === true
                "
              >
                Upload
                <v-icon right dark>mdi-cloud-upload</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-if="currentFile">
            <v-col cols="12" sm="12" md="12">
              <v-data-table
                :headers="headers"
                :items="itemsFInfrastructureComputed"
                item-key="name"
                class="elevation-1"
                height="400"
                calculate-widths
                hide-default-footer
                disable-pagination
              >
                <template v-slot:[`item.number`]="{ item }">
                  <span
                    ><v-icon small color="green" v-if="item.statusUpload === 1"
                      >mdi-check-circle</v-icon
                    ></span
                  >
                  <span
                    ><v-icon small color="red" v-if="item.statusUpload === 2"
                      >mdi-close</v-icon
                    ></span
                  >
                  {{ item.number }}
                </template>

                <template v-slot:[`item.district`]="{ item }">
                  <div>
                    {{ item.district }}
                  </div>
                  <div class="caption green--text" v-if="item.fsubAreaBean > 0">
                    {{ lookupFSubArea(item.fsubAreaBean).description }}
                  </div>
                  <div v-else class="red--text font-weight-bold">error</div>
                </template>
                <template v-slot:[`item.city`]="{ item }">
                  <div>
                    {{ item.city }}
                  </div>
                  <div class="caption green--text" v-if="item.fareaBean > 0">
                    {{ lookupFArea(item.fareaBean).description }}
                  </div>
                  <div v-else class="red--text font-weight-bold">error</div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <div v-if="progress">
            <div>
              <v-progress-linear
                v-model="progress"
                color="light-blue"
                height="25"
                reactive
              >
                <strong>{{ progress }} %</strong>
              </v-progress-linear>
            </div>
          </div>

          <v-row v-if="previewImage" justify="center">
            <v-img
              class="ma-2"
              :src="previewImage"
              alt=""
              max-width="300px"
              max-height="300px"
            ></v-img>
          </v-row>
        </v-card-text>
      </v-card>

      <v-alert v-if="message" border="left" color="blue-grey" dark>
        {{ message }}
      </v-alert>
    </v-dialog>

    <v-dialog v-model="dialogProgres" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please Wait...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import readXlsxFile from "read-excel-file";
import FInfrastructure from "@/models/f-infrastructure";
import FInfrastructureService from "../../../services/apiservices/f-infrastructure-service";

export default {
  name: "ImportExcel",
  props: {
    parentId: Number,
    itemsFDivision: [],
    itemsFInfrastructureType: [],
    itemsFArea: [],
    itemsFSubArea: [],
  },
  components: {},
  data() {
    return {
      sudahUpload: false,

      dialogProgres: false,
      dialogShow: false,
      formDialogOptions: {
        color: "grey lighten-3",
        width: 800,
        zIndex: 200,
        noconfirm: false,
      },

      currentFile: undefined,
      previewImage: undefined,

      fdivisionBeanSelected: 0,
      finfrastructureTypeBeanSelected: 0,
      rulesNotEmtpy: [(v) => !!v || "Tidak boleh kosong"],

      progress: 0,
      message: "",
      description: "",

      headers: [
        {
          text: "No",
          value: "number",
          width: "8%",
          sortable: false,
        },
        { text: "Nama", value: "description" },
        { text: "Kecamatan", value: "district" },
        { text: "Kab/Kota", value: "city" },
        { text: "Lat", value: "lat" },
        { text: "Lon", value: "lon" },
        { text: "tahun", value: "tahun" },
        { text: "remark1", value: "remark1" },
        { text: "remark2", value: "remark2" },
        { text: "remarkInt1", value: "remarkInt1" },
        { text: "remarkDouble1", value: "remarkDouble1" },
      ],
      itemsFInfrastructure: [],
    };
  },
  computed: {
    itemsFInfrastructureComputed() {
      return this.itemsFInfrastructure;
    },
  },

  methods: {
    selectFile(file) {
      this.currentFile = file;
      // this.previewImage = URL.createObjectURL(this.currentFile);
      this.progress = 0;
      this.message = "";

      let rowKe = 0;
      readXlsxFile(this.currentFile).then((rows) => {
        rows.forEach((item) => {
          if (rowKe > 0) {
            // console.log(JSON.stringify(item[1]))
            // console.log(JSON.stringify(item[1]))

            let fFInfrastructureBean = new FInfrastructure();
            fFInfrastructureBean.number = rowKe + 1;

            // if (item[1].toUpperCase() ==="FISIK" || item[1].toUpperCase() ==="FSK" ) {
            //   fFInfrastructureBean.jenisPekerjaan = "FSK"
            // }else {
            //   fFInfrastructureBean.jenisPekerjaan = "NONFSK"
            // }

            fFInfrastructureBean.statusUpload = 0;
            fFInfrastructureBean.description =
              item[0] !== null ? item[0].trim() : "";
            fFInfrastructureBean.district =
              item[1] !== null ? item[1].trim() : "";
            fFInfrastructureBean.city = item[2] !== null ? item[2].trim() : "";
            fFInfrastructureBean.lat = item[3] !== null ? Number(item[3]) : 0.0;
            fFInfrastructureBean.lon = item[4] !== null ? Number(item[4]) : 0.0;

            fFInfrastructureBean.tahun = item[5] !== null ? Number(item[5]) : 0;

            fFInfrastructureBean.remark1 =
              item[6] !== null ? item[6].trim() : "";
            fFInfrastructureBean.remark2 =
              item[7] !== null ? item[7].trim() : "";
            fFInfrastructureBean.remarkInt1 =
              item[8] !== null ? Number(item[8]) : 0;
            fFInfrastructureBean.remarkDouble1 =
              item[9] !== null ? Number(item[9]) : 0.0;

            const kabKota = this.itemsFArea.filter(
              (x) =>
                x.description.trim().toUpperCase() ===
                  fFInfrastructureBean.city.trim().toUpperCase() ||
                x.description.trim().replace(/\s+/g, "").toUpperCase() ===
                  fFInfrastructureBean.city
                    .trim()
                    .replace(/\s+/g, "")
                    .toUpperCase()
            );
            try {
              fFInfrastructureBean.fareaBean = kabKota[0].id;
            } catch (e) {
              e.toString();
            }

            const kecamatan = this.itemsFSubArea.filter(
              (x) =>
                x.description.trim().toUpperCase() ===
                  fFInfrastructureBean.district.trim().toUpperCase() ||
                x.description.trim().replace(/\s+/g, "").toUpperCase() ===
                  fFInfrastructureBean.district
                    .trim()
                    .replace(/\s+/g, "")
                    .toUpperCase()
            );

            try {
              fFInfrastructureBean.fsubAreaBean = kecamatan[0].id;
            } catch (e) {
              e.toString();
            }

            // if (isNaN(Date.parse(fFInfrastructureBean.tglAdendum))){
            //   fFInfrastructureBean.tglAdendum = '2020-01-01'
            // }

            this.itemsFInfrastructure.push(fFInfrastructureBean);
          }

          rowKe++;
        });
      });
    },

    startUpload() {
      if (!this.currentFile) {
        this.message = "Please select an Image!";
        return;
      }
      if (
        this.finfrastructureTypeBeanSelected === undefined ||
        this.finfrastructureTypeBeanSelected === 0
      ) {
        this.message = "Tipe Infrastruktur Belum Dipilih!";
        return;
      }

      let items = [];
      this.itemsFInfrastructure.forEach((item) => {
        let newItem = item;
        newItem.id = 0;
        newItem.finfrastructureTypeBean = this.finfrastructureTypeBeanSelected;

        if (this.fdivisionBeanSelected !== 0) {
          newItem.fdivisionBean = this.fdivisionBeanSelected;
        } else {
          const div = this.itemsFDivision.filter(
            (x) =>
              x.description.trim().toUpperCase() ===
                newItem.city.trim().toUpperCase() ||
              x.description.trim().replace(/\s+/g, "").toUpperCase() ===
                newItem.city.trim().replace(/\s+/g, "").toUpperCase()
          );
          try {
            newItem.fdivisionBean = div[0].id;
          } catch (e) {
            e.toString();
          }
        }

        // const kabKota = this.itemsFArea.filter(x => x.description.trim().toUpperCase() === newItem.city.trim().toUpperCase() ||
        //     x.description.trim().replace(/\s+/g, '').toUpperCase() === newItem.city.trim().replace(/\s+/g, '').toUpperCase())
        // try{
        //   newItem.fareaBean = kabKota[0].id
        // }catch (e) {
        //   e.toString()
        // }
        //
        // const kecamatan = this.itemsFSubArea.filter(x => x.description.trim().toUpperCase() === newItem.district.trim().toUpperCase() ||
        //     x.description.trim().replace(/\s+/g, '').toUpperCase() === newItem.district.trim().replace(/\s+/g, '').toUpperCase())
        //
        // try{
        //   newItem.fsubAreaBean = kecamatan[0].id
        // }catch (e) {
        //   e.toString()
        // }

        if (
          newItem.fdivisionBean > 0 &&
          newItem.fareaBean > 0 &&
          newItem.fsubAreaBean > 0
        ) {
          items.push(newItem);
        } else {
          console.log(JSON.stringify(newItem));
        }
      });

      FInfrastructureService.createFInfrastructureMultiple(items).then(
        (response) => {
          // console.log(response.data.length)

          this.itemsFInfrastructure = [];

          response.data.forEach((itemResponse) => {
            const newItem = Object.assign({}, itemResponse);
            try {
              this.$nextTick(() => {
                newItem.statusUpload = 1;
                this.itemsFInfrastructure.push(newItem);
              });
            } catch (e) {
              newItem.statusUpload = 2;
              this.itemsFInfrastructure.push(newItem);
              console.log(e);
            }
          });
          this.sudahUpload = true;
        },
        (error) => {
          console.log(error.data);
        }
      );
    },

    showDialog() {
      this.dialogShow = true;
      this.currentFile = undefined;
      this.previewImage = undefined;
      this.progress = 0;
      this.message = "";

      this.sudahUpload = false;

      this.itemsFInfrastructure = [];
    },
    closeDialog() {
      if (!this.isItemModified) {
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
      }
      if (this.sudahUpload) {
        this.$emit("importExcelCompleted", "OKE");
      }
    },
    passingEventFromCloseConfirm(value) {
      if (value === "OKE") this.dialogShow = false;
    },

    lookupFSubArea(fsubAreaBean) {
      const str = this.itemsFSubArea.filter((x) => x.id === fsubAreaBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    lookupFArea(fareaBean) {
      const str = this.itemsFArea.filter((x) => x.id === fareaBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
</style>
