
const ESchemaSalesCs = Object.freeze([
    {
        column: 'Nomor',
        type: Number,
        value: item => item.id
    },
    {
        column: 'Division',
        type: String,
        width:15,
        value: item => item.fdivision.kode1
    },

    {
        column: 'Kode',
        type: String,
        value: item => item.kode1
    },
    // {
    //     column: 'Tanggal',
    //     type: Date,
    //     format: 'dd-MMM-yyyy',
    //     value: item => new Date(item.orderDate)
    // },
    {
        column: 'Description',
        type: String,
        width:20,
        value: item => item.description
    },
    {
        column: 'notes',
        type: String,
        width:20,
        value: item => item.notes
    },
    {
        column: 'Luas',
        type: Number,
        format: '#,##0',
        value: item => item.luas
    },

    {
        column: 'website',
        type: String,
        width:15,
        value: item => item.website
    },
    {
        column: 'fax',
        type: String,
        width:20,
        value: item => item.fax
    },
    {
        column: 'phone',
        type: String,
        width:25,
        value: item => item.phone
    },
    {
        column: 'email',
        type: String,
        width:25,
        value: item => item.email
    },

    {
        column: 'tahun',
        type: Number,
        width:25,
        value: item => item.tahun
    },
    {
        column: 'address',
        type: String,
        value: item => item.address
    },
    {
        column: 'district',
        type: String,
        width:25,
        value: item => item.district
    },
    {
        column: 'city',
        type: String,
        width:20,
        value: item => item.city
    },
    {
        column: 'state',
        type: String,
        width:20,
        value: item => item.state
    },
    {
        column: 'remark1',
        type: String,
        width:25,
        value: item => item.remark1
    },
    {
        column: 'remark2',
        type: String,
        width:25,
        value: item => item.remark2
    },
    {
        column: 'remark3',
        type: String,
        width:25,
        value: item => item.remark3
    },
    {
        column: 'remark4',
        type: String,
        width:25,
        value: item => item.remark4
    },
    {
        column: 'remark5',
        type: String,
        width:25,
        value: item => item.remark5
    },

    {
        column: 'remarkInt1',
        type: Number,
        width:25,
        value: item => item.remarkInt1
    },
    {
        column: 'remarkInt2',
        type: Number,
        width:25,
        value: item => item.remarkInt2
    },

    {
        column: 'remarkDouble1',
        type: Number,
        format: '#,##0',
        width:25,
        value: item => item.remarkDouble1
    },
    {
        column: 'remarkDouble2',
        type: Number,
        format: '#,##0',
        width:25,
        value: item => item.remarkDouble2
    },



    {
        column: 'Lon',
        type: Number,
        format: '#,##0',
        value: item => item.lat
    },
    {
        column: 'Lon',
        type: Number,
        format: '#,##0',
        value: item => item.lon
    },

])


export default ESchemaSalesCs